const COUPON_STATUS_ENUM = {
    '0': {
        text: '已停用',
        color: 'red',
    },
    '1': {
        text: '已启用',
        color: 'green',
    },
}

export {
    COUPON_STATUS_ENUM,
}