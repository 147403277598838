import React from 'react'
import { message, Select, Button, Table, Modal } from 'antd';
import { post } from 'common/utils.js';
import style from './GuestsPane.module.scss';
import { getColumns } from './utils';

const Option = Select.Option;
export default class GuestsPane extends React.Component {
    constructor(props) {
        super(props);
        let d = props.data;
        this.state = {
            loading: false,
            options: null,
            selectedUser: null,
            registered_user_list: null,
            unregistered_user_list: null,
            rsvd_num: props?.rsvd_num ?? null,
        };
        this.user_list = props?.user_list ?? null;
    }

    componentDidMount() {
        this.getUserOptions("");
        this.setInitUserList();
    }

    getUserOptions = (keyword) => {
        post(process.env.REACT_APP_SERVER_PATH + "api/users/get_list", {
            page: 1,
            page_size: 20,
            keyword: keyword ?? "",
        })
            .then(retData => {
                // console.log(retData);
                let arr = [];
                if (retData?.data?.errcode === 0) {
                    arr = retData?.data?.results ?? [];
                };
                arr = arr
                    .map((item, index) => Object.assign({}, item, { key: `${index}` }));
                // console.log(arr);
                this.setState({ options: arr });
            });
    };

    setInitUserList = () => {
        const user_list = this.user_list;
        // 根据传入的list里面元素有没有user_id进行已注册和未注册分组
        const [registered_user_list, unregistered_user_list] = (user_list ?? [])
            .reduce((pV, cV) => {
                let [r_list, ur_list] = pV;
                if (typeof cV?.user_id === 'number' && !isNaN(cV?.user_id)) {
                    r_list = r_list.concat([cV]);
                } else {
                    ur_list = ur_list.concat([cV]);
                };
                return [r_list, ur_list];
            }, [[], []])
            .map(list => {
                return list
                    .map((item, index) => {
                        return Object.assign({}, item, { key: `${index}` });
                    });
            });
        // console.log("user_list", user_list, registered_user_list, unregistered_user_list);
        this.setState({
            registered_user_list,
            unregistered_user_list,
        });
    }

    onSearch = v => {
        this.getUserOptions(v ?? "");
    }

    addOnClick = key => {
        return () => {
            if (key === "registered_user_list") {
                const { selectedUser } = this.state;
                // 必然存在
                const { [key]: list } = this.state;
                // 需要检查是不是已经存在
                // 检查rowItem，有editInfo的检查editInfo
                const sameUserIdItems = (list ?? []).filter(({ user_id, editInfo }) => user_id === selectedUser?.id || selectedUser?.id === editInfo?.user_id);
                if (sameUserIdItems.length > 0) {
                    message.warning("该用户已存在！请勿重复选择");
                    return;
                };
                const { id, ...rest } = selectedUser;
                let newItem = {};
                newItem = Object.assign({}, newItem, { editInfo: Object.assign({}, rest, { user_id: id }) });
                const n_list = (list ?? [])
                    .concat([newItem])
                    .map((item, index) => Object.assign({}, item, { key: `${index}` }));
                this.setState({ [key]: n_list, selectedUser: null });
                return;
            };
            // 单纯加一行，并进入编辑状态
            const { [key]: list } = this.state;
            let newItem = {};
            newItem = Object.assign({}, newItem, { editInfo: newItem });
            const n_list = (list ?? [])
                .concat([newItem])
                .map((item, index) => Object.assign({}, item, { key: `${index}` }));
            this.setState({ [key]: n_list });
        };
    }

    getColumns = (key) => {
        const commonSetValue = (rKey, itemKey, itemValueFunc) => {
            const { [key]: list } = this.state;
            const n_list = (list ?? [])
                .map(item => {
                    if (item?.key === rKey) {
                        // 取消编辑状态，删除editInfo;
                        const itemValue = itemValueFunc(item);
                        return Object.assign({}, item, { [itemKey]: itemValue });
                    };
                    return item;
                });
            // console.log(key, rKey, n_list);
            this.setState({ [key]: n_list });
        };
        const editOnClick = (rKey) => {
            return () => {
                // 开始编辑，生成editInfo
                commonSetValue(rKey, "editInfo", (r) => r);
            };
        };
        const cancelEdit = (rKey) => {
            return () => {
                // 如果是新建取消，应该删除本行，通过rItem的id判断是新的还是旧的
                const { [key]: list } = this.state;
                const n_list = (list ?? [])
                    .map(item => {
                        if (item?.key === rKey) {
                            // 取消编辑状态，删除editInfo;
                            if (typeof item?.editInfo?.id === 'number' && !isNaN(item?.editInfo?.id)) {
                                return Object.assign({}, item, { editInfo: null });
                            } else {
                                return null;
                            };
                        };
                        return item;
                    })
                    .filter((item) => !!item);
                // console.log(key, rKey, n_list);
                this.setState({ [key]: n_list });
            };
        };
        const saveOnClick = (rKey) => {
            return () => {
                // 需要发请求
                const { [key]: list } = this.state;
                const currRow = list.filter(({ key }) => key === rKey)[0] ?? null;
                // console.log("saveOnClick", currRow);
                const { editInfo } = currRow;
                const { name, gender, identity_number, user_id, id, mobile, mobile_region, identity_type } = editInfo;
                let save_info = {
                    name,
                    gender,
                    identity_type,
                    identity_number,
                    mobile_region,
                    mobile,
                };
                // console.log(save_info);
                // 如果没有id，是新增的
                const order_id = this.props?.id ?? null;
                if (typeof order_id === 'number' && !isNaN(order_id)) {
                    if (typeof id === 'number' && !isNaN(id)) {
                        // 修改
                        post(process.env.REACT_APP_SERVER_PATH + `api/pc/catering/order/update_user_info/${id}`, save_info)
                            .then(retData => {
                                // console.log(retData);
                                if (retData?.data?.errcode === 0) {
                                    // 保存成功
                                    // 本地替换
                                    const { [key]: list } = this.state;
                                    const n_list = (list ?? [])
                                        .map(item => {
                                            if (item?.key === rKey) {
                                                return Object.assign({}, item, save_info, { editInfo: null });
                                            };
                                            return item;
                                        });
                                    this.setState({ [key]: n_list });
                                    message.success('保存成功');
                                    // 通知外部，但内部不变
                                    this.props.onRefresh && this.props.onRefresh();
                                } else {
                                    // 保存失败，提示
                                    const msg = retData?.data?.msg ?? "保存失败, 请重试!";
                                    message.warning(msg);
                                };
                            });
                    } else {
                        // 首次保存
                        // 加上user_id
                        save_info = Object.assign({}, save_info, { user_id: user_id ?? null });
                        post(process.env.REACT_APP_SERVER_PATH + `api/pc/catering/order/add_user_info/${order_id}`, save_info)
                            .then(retData => {
                                // console.log(retData);
                                if (retData?.data?.errcode === 0) {
                                    // 保存成功
                                    const id = retData?.data?.id ?? null;
                                    // 本地保存
                                    const { [key]: list } = this.state;
                                    const n_list = (list ?? [])
                                        .map(item => {
                                            if (item?.key === rKey) {
                                                return Object.assign({}, item, save_info, { editInfo: null, id });
                                            };
                                            return item;
                                        });
                                    this.setState({ [key]: n_list });
                                    message.success('保存成功');
                                    this.props.onRefresh && this.props.onRefresh();
                                } else {
                                    // 保存失败，提示
                                    const msg = retData?.data?.msg ?? "保存失败, 请重试!";
                                    message.warning(msg);
                                };
                            });
                    };
                };
            };
        };
        const editValueFunc = (r, key, value) => {
            return Object.assign({}, r?.editInfo ?? {}, { [key]: value });
        };
        const inputOnChange = (rKey, itemKey) => {
            return (e) => {
                const v = e.target.value;
                commonSetValue(rKey, "editInfo", (r) => editValueFunc(r, itemKey, v));
            };
        };
        const delOnClick = (rKey) => {
            return () => {
                Modal.confirm({
                    content: `确认删除该用户吗?`,
                    onOk: () => {
                        // console.log("delOnClick");
                        const { [key]: list } = this.state;
                        const del_id = (list ?? []).filter(({ key }) => key === rKey)[0]?.id ?? null;
                        if (typeof del_id === 'number' && !isNaN(del_id)) {
                            post(process.env.REACT_APP_SERVER_PATH + `api/pc/catering/order/remove_user_info/${del_id}`, {})
                                .then(retData => {
                                    // console.log(retData);
                                    if (retData?.data?.errcode === 0) {
                                        // 删除成功
                                        // 本地保存
                                        const { [key]: list } = this.state;
                                        const n_list = (list ?? []).filter(({ key }) => key !== rKey);
                                        this.setState({ [key]: n_list });
                                        message.success('删除成功');
                                        this.props.onRefresh && this.props.onRefresh();
                                    } else {
                                        message.warning("删除失败, 请重试!");
                                    };
                                });
                        };
                    },
                });
            };
        };
        const selectValueOnChange = (rKey, itemKey) => {
            return (v) => {
                commonSetValue(rKey, "editInfo", (r) => editValueFunc(r, itemKey, v));
            };
        };
        const info = {
            editOnClick,
            cancelEdit,
            saveOnClick,
            inputOnChange,
            delOnClick,
            selectValueOnChange,
            type: key,
        };
        return getColumns(info);
    };

    selectOnChange = (v) => {
        const { options } = this.state;
        const n_sel = (options ?? []).filter(({ key }) => key === v)[0] ?? null;
        // console.log(n_sel);
        this.setState({ selectedUser: n_sel });
    };

    render() {
        const {
            options, registered_user_list, unregistered_user_list, rsvd_num,
            selectedUser,
        } = this.state;

        // let disableAdd = true;
        // if (typeof rsvd_num === 'number' && !isNaN(rsvd_num)) {
        //     if ((registered_user_list ?? []).length + (unregistered_user_list ?? []).length < rsvd_num) {
        //         disableAdd = false;
        //     };
        // };

        return (
            <div
                className={style["wrapper"]}
            >
                <div className="tool_wrapper">
                    <div className="title">已注册用户</div>
                    <Select
                        style={{ width: 200, marginLeft: 12 }}
                        showSearch
                        onSearch={this.onSearch}
                        value={selectedUser?.key ?? null}
                        onChange={this.selectOnChange}
                        filterOption={false}
                        allowClear
                    >
                        {
                            (options ?? [])
                                .map((option, index) => {
                                    const { key, name, mobile } = option;
                                    return (
                                        <Option value={key} key={index}>{name ?? "--"} {mobile ?? "--"}</Option>
                                    );
                                })
                        }
                    </Select>
                    <Button type="primary" style={{ marginLeft: 12 }} disabled={!selectedUser} onClick={this.addOnClick("registered_user_list")}>添加</Button>
                </div>
                <Table
                    size="small"
                    pagination={false}
                    bordered={true}
                    style={{ marginTop: 4 }}
                    dataSource={registered_user_list ?? []}
                    columns={this.getColumns("registered_user_list")}
                >
                </Table>
                <div className="tool_wrapper">
                    <div className="title">未注册用户</div>
                    <Button type="primary" style={{ marginLeft: 12 }} disabled={false} onClick={this.addOnClick("unregistered_user_list")}>添加</Button>
                </div>
                <Table
                    size="small"
                    pagination={false}
                    bordered={true}
                    style={{ marginTop: 4 }}
                    dataSource={unregistered_user_list ?? []}
                    columns={this.getColumns("unregistered_user_list")}
                >
                </Table>
                <div className="btn-line" style={{ justifyContent: 'space-around' }}>
                    <Button onClick={() => {
                        this.props.onClose && this.props.onClose();
                    }}>关闭</Button>
                </div>
            </div>
        )
    }
}
