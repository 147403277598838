import React from 'react'
import { Tabs, Spin, message, Modal, Select, Descriptions, Button, DatePicker, Input, Badge} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import moment from 'moment';
import { get, post, patch } from 'common/utils.js';
import style from './OrderInfoPane.module.scss';
import { getMyPermissions } from 'common/AuthPermission.js';
import { ORDER_STATUS_ENUM } from '../constrants.js';

const Option = Select.Option;
const TabPane = Tabs.TabPane;

export default class OrderInfoPane extends React.Component {
    constructor(props) {
        super(props);
        let c_order_info = props.c_order_info;
        this.state = {
            myPermissions: getMyPermissions() ?? [],
            loading: false,
            mode: "DISPLAY",
            datePickerValue: null,
            saving: false,
        }
    }

    componentDidMount() {
    }

    saveInfo = () => {
        let self = this;
        self.setState({saving: true});
        let params = {};
        if(this.state.mode === 'EDIT_TIME'){
            params['day'] = moment(this.state.datePickerValue).format("YYYY-MM-DD");
        }else if(this.state.mode === 'EDIT_REMARK'){
            params['remark'] = this.state.remark;
        }
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/modify_order_info/'+this.props.c_order_info.id, params).then(retData => {
            self.setState({saving: false});
            if (retData.data) {
                if(retData.data.errcode === 0){
                    message.success('操作成功');
                    self.setState({mode: "DISPLAY"});
                    self.props.onRefresh && self.props.onRefresh();
                }else if(retData.data.errcode === 5001){
                    message.error('新日期座位数不够');
                }else{
                    message.error('操作失败');
                }
            };
        });
    }

    render() {
        let self = this;
        const { visible, c_order_info, pay_order_info } = this.props;
        const { saving, datePickerValue, remark, myPermissions } = this.state;

        let gender_text = '';
        if(c_order_info.user_gender == 1){
            gender_text = '先生';
        }else if(c_order_info.user_gender == 2){
            gender_text = '女士';
        }

        return (
            <div
                className={style["wrapper"]}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <Descriptions column={2} bordered>
                    <Descriptions.Item label="订单流水号" span={2}>{c_order_info.sn}</Descriptions.Item>
                    <Descriptions.Item label="订单状态"><Badge color={ORDER_STATUS_ENUM[c_order_info.status + ''] ? ORDER_STATUS_ENUM[c_order_info.status + ''].color : null} />{ORDER_STATUS_ENUM[c_order_info.status + ''].text}</Descriptions.Item>
                    <Descriptions.Item label="预订号">{c_order_info.rez_code?c_order_info.rez_code:'尚未生成'}</Descriptions.Item>
                    <Descriptions.Item label="预订项目">{c_order_info.service_name}</Descriptions.Item>
                    <Descriptions.Item label="预订席位">{c_order_info.rsvd_num}</Descriptions.Item>
                    <Descriptions.Item label="预订日期">
                    {
                        this.state.mode !== 'EDIT_TIME' &&
                        <div
                            className={"info_item "+(([1, 2].indexOf(c_order_info.status) >= 0 && myPermissions.includes('CATER/ORDER/MNG/MODIFY_DAY')) ? "enable_edit":"")}
                            onClick={()=>{
                                if(self.state.mode !== 'DISPLAY'){
                                    message.error("请先保存");
                                }else if([1, 2].indexOf(c_order_info.status) >= 0 && myPermissions.includes('CATER/ORDER/MNG/MODIFY_DAY')){
                                    self.setState({mode: 'EDIT_TIME', datePickerValue: moment(c_order_info.day)});
                                }
                            }}
                        >
                            {moment(c_order_info.day).format('YYYY年M月D日')}
                            {([1, 2].indexOf(c_order_info.status) >= 0 && myPermissions.includes('CATER/ORDER/MNG/MODIFY_DAY')) && <FormOutlined className="edit_icon" />}
                        </div>
                    }
                    {
                        this.state.mode === 'EDIT_TIME' &&
                        <div className="">
                            <DatePicker size="small" value={datePickerValue} onChange={v=>this.setState({datePickerValue:v})} />
                        </div>
                    }
                    </Descriptions.Item>
                    <Descriptions.Item label="备注">
                    {
                        this.state.mode !== 'EDIT_REMARK' &&
                        <div
                            className="info_item"
                            onClick={()=>{
                                if(self.state.mode !== 'DISPLAY'){
                                    message.error("请先保存");
                                }else{
                                    self.setState({mode: 'EDIT_REMARK', remark: c_order_info.remark});
                                }
                            }}
                            style={{width: 180}}
                        >
                            {c_order_info.remark}<FormOutlined className="edit_icon" />
                        </div>
                    }
                    {
                        this.state.mode === 'EDIT_REMARK' &&
                        <div className="" style={{width: 180}}>
                            <Input size="small" placeholder="请输入备注" value={remark} onChange={e=>this.setState({remark: e.target.value})} />
                        </div>
                    }
                    </Descriptions.Item>
                    <Descriptions.Item label="预订联系人">{c_order_info.user_name} {gender_text}</Descriptions.Item>
                    <Descriptions.Item label="联系方式">{c_order_info.user_mobile}</Descriptions.Item>
                </Descriptions>
                {
                    (this.state.mode === 'EDIT_TIME' || this.state.mode === 'EDIT_REMARK') &&
                    <div className="btn-line">
                        <Button onClick={()=>this.setState({mode: 'DISPLAY'})}>取消</Button>
                        <Button type="primary" onClick={()=>this.saveInfo()} loading={saving}>保存</Button>
                    </div>
                }
                {
                    this.state.mode === 'DISPLAY' &&
                    <div className="btn-line" style={{justifyContent: "space-around"}}>
                        <Button onClick={()=>{
                            self.props.onClose && self.props.onClose();
                        }}>关闭</Button>
                    </div>
                }
            </div>
        )
    }
}
