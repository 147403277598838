
const menu = [
    {
        name: '基础信息设置',
    },
    {
        name: '餐位计划设置',
    },
]
    .map((item, index) => Object.assign({}, item, { key: `${index}` }));

const timeMenu = [
    {
        name: "本月",
        delta: 0,
    },
    {
        name: "下月",
        delta: 1,
    },
    {
        name: "下下月",
        delta: 2,
    },
]
    .map((item, index) => Object.assign({}, item, { key: `${index}` }));

const getDataSource = () => {
    return new Array(30)
        .fill(0)
        .map((v, index) => {
            return {
                key: `${index}`,
                time: `8/${index + 1}`,
                content: Math.floor(Math.random() * 3),
            };
        });
};

export {
    menu,
    timeMenu,
    getDataSource,
};