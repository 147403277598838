import React, { Component } from 'react';
import Config from '../../common/config.js';
import { get, post, configWX } from 'common/utils.js';
import { Button } from 'antd';
import wx from 'weixin-js-sdk';
import style from './ScanQRCode.module.scss';
import CaterOrderList from './CaterOrderList.js';
import CaterOrderDetails from './CaterOrderDetails.js';

export default class ScanQRCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageType: 'FAILED',
            isFirstEntered: true,
            name: '',
            phone: '',
            gender: null,
        };

        let self = this;
        // if(window.wxIsReady == true){
        //     // alert('window.wxIsReady == true');
        //     this.doWXScan();
        // }else{
        //     document.body.addEventListener('wxIsReady', function (e) {
        //         // alert('wxIsReady event triggered');
        //         self.doWXScan();
        //     }, false);
        // }

        // wx.ready(()=>{
        //     self.setState({wechatReady: true});
        //     if(self.state.isFirstEntered == true){
                
        //     }
        // });
        // wx.error(res=>{
        //     alert('扫码初始化失败：'+JSON.stringify(res));
        //     self.setState({wechatReady: false});
        // });
    }

    componentWillMount() {
        configWX();
    }

    doWXScan = () => {
        let self = this;
        // alert('doWXScan');
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: retData=>{
                let content = retData.resultStr;
                if(content.indexOf('CATER_V1_') >= 0){
                    let items = content.split('_');
                    if(items.length === 3){
                        let orderId = items[2];
                        self.props.history.push('/Mobile/CaterOrderDetails?id='+orderId);
                    }
                }else if(content.indexOf('USER') >= 0){
                    let items = content.split('_');
                    if(items.length == 2){
                        let userId = items[1];
                        self.props.history.push('/Mobile/CaterOrderList?userId='+userId);
                    }
                }
                // alert(retData.resultStr)
            }
        });
    }

    render() {
        let self = this;
        return (
            <div className={style["wrapper"]}>
                {
                    this.state.pageType == 'FAILED' &&
                    <React.Fragment>
                        <div className="downBlkWrapper">
                            {
                                this.state.failureText1 &&
                                <div className="failureText text1" style={{marginTop: '64px'}}>
                                    {this.state.failureText1}
                                </div>
                            }
                            {
                                this.state.preVerifyTime &&
                                <div className="preVerifyTime">
                                    该二维码已于&nbsp;{this.state.preVerifyTime}&nbsp;被核销
                                </div>
                            }
                            {
                                this.state.failureText2 &&
                                <div className="failureText text2" style={{marginTop: '7px', fontSize: '32px'}}>
                                    {this.state.failureText2}
                                </div>
                            }
                            <div className="reserveBtn" onClick={this.doWXScan}>
                                <Button className="reserveBtnBorder" type="primary" size="large">扫码</Button>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    this.state.pageType == 'SUCCEED' &&
                    <React.Fragment>
                        <div className="successText1">恭喜您</div>
                        <div className="successText2">&nbsp;&nbsp;确认成功！</div>
                        <div className="shortDivider"></div>
                        <div className="infoBlock">
                            姓名：{this.state.name}<br/>
                            手机：{this.state.phone}
                        </div>
                        <div className="downBlkWrapper">
                            <div className="reserveBtn" style={{marginTop: '50px'}} onClick={this.doWXScan}>
                                <div className="reserveBtnBorder">继续扫码</div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}
