import React from 'react';
import style from './index.module.scss';
import { menu, timeMenu, getDataSource } from './utils';
import { get, post } from 'common/utils.js';
import SeatItem from './SeatItem';
import { Button, Input, Modal, Switch, Tabs, Radio, DatePicker, Table } from 'antd';
import moment from 'moment';
import DayInfoEditor from './DayInfoEditor.js';

const WEEK_DAY_NAMES = {
    '0': "周日",
    '1': "周一",
    '2': "周二",
    '3': "周三",
    '4': "周四",
    '5': "周五",
    '6': "周六",
};

const { TabPane } = Tabs;
export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: menu?.[0] ?? null,
            timeItemSelected: timeMenu?.[0] ?? null,
            monthText: moment().format("YYYY年M月"),
            dataList: [],
            editorData: {},
            monthOffset: 0,
        };
    }

    componentWillMount() {
        this.getDataByMonthOffset(0)
    }

    getDataByMonth = () => {
        
    }

    getDataByMonthOffset = (monthOffset) => {
        let day_begin = moment().startOf('month');
        if(monthOffset === 1){
            day_begin.endOf('month').add('days', 1);
        }else if(monthOffset === 2){
            day_begin.endOf('month').add('days', 1).endOf('month').add('days', 1);
        }
        let day_end = moment(day_begin).endOf('month');
        this.setState({monthText: moment(day_begin).format("YYYY年M月"), monthOffset: monthOffset});
        this.getDataList(day_begin, day_end);
    }
    
    getDataList = (day_begin, day_end) => {
        let self = this;
        self.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/get_daily_info', {
            day_begin: day_begin.format('YYYY-MM-DD'),
            day_end: day_end.format('YYYY-MM-DD'),
        }).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results})
            };
        });
    }

    timeOnChange = (e) => {
        const key = e.target.value;
        const nextSelected = (timeMenu ?? [])
            .filter(({ key: mKey }) => mKey === key)[0] ?? null;
        this.setState({
            timeItemSelected: nextSelected,
        }, _=>this.getDataByMonthOffset(nextSelected.delta));
    };

    // monthOnChange = (month) => {
    //     this.setState({ month });
    // };

    render() {
        const { selected, timeItemSelected, dataList, editorVisible, editorData, loading, monthText } = this.state;

        return (
            <div className={style['wrapper']}>
                <div className="content scroll_bar_restyle">
                    <div className="block">
                        <div className="top">
                            <div className="time_bar">
                                <div style={{fontWeight: 'bold', marginLeft: '32px', fontSize: '16px'}}>{monthText}</div>
                                <Radio.Group value={timeItemSelected?.key} onChange={this.timeOnChange} style={{ marginLeft: 50 }}>
                                    {
                                        (timeMenu ?? [])
                                            .map((item, index) => {
                                                const { name, key } = item;
                                                return (
                                                    <Radio.Button value={key} key={index}>{name}</Radio.Button>
                                                );
                                            })
                                    }
                                </Radio.Group>
                            </div>
                        </div>
                        <Table
                            rowKey="day"
                            dataSource={dataList}
                            loading={loading}
                            bordered
                            showHeader={false}
                            pagination={false}
                            size="small"
                            columns={[
                                {
                                    dataIndex: 'day',
                                    width: 150,
                                    align: 'center',
                                    render: (text) => 
                                        <div>
                                            {moment(text).format('YYYY年M月D日')}
                                            <br/>
                                            {WEEK_DAY_NAMES[moment(text).day()+'']}
                                        </div>
                                    
                                },
                                {
                                    dataIndex: 'catering_list',
                                    render: (t, r, rIndex) => {
                                        return (
                                            <div className={style["content_wrapper"]}>
                                            {
                                                t.filter(item=>item.status===1).map((item, index)=>
                                                    <div className="item" key={index}>
                                                        <SeatItem
                                                            color={item.service_id === 1 ? "#d8cfc1" : "#d3d5c4"}
                                                            name={item.name}
                                                            total_num={item.total_num}
                                                            rsvd_num={item.rsvd_num}
                                                            price={item.price}
                                                        />
                                                    </div>
                                                )
                                            }
                                            </div>
                                        );
                                    },
                                },
                                {
                                    dataIndex: "action",
                                    width: 145,
                                    align: 'center',
                                    render: (t, r, rIndex) => {
                                        return (
                                            <Button
                                                type="link"
                                                onClick={()=>this.setState({editorVisible: true, editorData: JSON.parse(JSON.stringify(r)) })}
                                            >修改</Button>
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
                {
                    editorVisible && 
                    <DayInfoEditor
                        visible={editorVisible}
                        data={editorData}
                        changeVisible={v => this.setState({ editorVisible: v }, ()=>this.getDataByMonthOffset(this.state.monthOffset))}
                        onRefresh={_=>this.getDataByMonthOffset(this.state.monthOffset)}
                    />
                }
            </div>
        )
    }
}
