import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import Header from './Header';
import LeftMenu from './Menu';

class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div className={style['wrapper']}>
                <div className='scroll_wrapper'>
                    <div className='content'>
                        <div className="left">
                            <LeftMenu />
                        </div>
                        <div className="right">
                            <Header />
                            <div className="content">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Page);
