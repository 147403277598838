import React, { Component } from 'react';
import Config from '../../common/config.js';
import { get, post, getQueryString } from '../../common/utils.js';
import moment from 'moment';
import { Descriptions, Button, message, Table, Badge } from 'antd';
import style from './CaterOrderDetails.module.scss';
import { ORDER_STATUS_ENUM } from '../CateringOrderList/constrants.js';

const Column = Table.Column;

export default class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: getQueryString('userId'),
            dataList: [],
            loading: false,
        };

        let self = this;
    }

    componentWillMount() {
        this.getDataList(this.state.userId);
    }

    getDataList = (userId) => {
        let self = this;
        self.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/get_list', {
            page: 1,
            page_size: 500,
            user_id: userId
        }).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results})
            };
        });
    }

    render() {
        let self = this;
        const { dataList, loading } = this.state;

        return (
            <div className={style["wrapper"]}>
                <Table
                    rowKey='id'
                    pagination={false}
                    bordered={true}
                    style={{marginTop: 4}}
                    dataSource={dataList}
                    loading={loading}
                    // size="default"
                >
                    <Column
                        title='预订日期'
                        key='day'
                        // width={50}
                        align='center'
                        render={(r)=>{
                            return moment(r.day).format('YYYY年M月D日');
                        }}
                    />
                    <Column
                        title='项目'
                        key='service_name'
                        // width={180}
                        render={(r)=>{
                            let service_name = ''
                            if(r['extra_data']){
                                return r['extra_data']['s_name'];
                            }
                        }}
                    />
                    <Column
                        title='订单状态'
                        key='status'
                        align='center'
                        dataIndex='status'
                        render={status => {
                            if (status in Object.keys(ORDER_STATUS_ENUM)) {
                                return (<span><Badge color={ORDER_STATUS_ENUM[status + ''] ? ORDER_STATUS_ENUM[status + ''].color : null} />{ORDER_STATUS_ENUM[status + ''].text}</span>);
                            } else {
                                return '';
                            }
                        }}
                    />
                    <Column
                        title='操作'
                        key='opt'
                        align='center'
                        render={(r)=><span style={{color: "#357CF7"}} onClick={_=>this.props.history.push('/Mobile/CaterOrderDetails?id='+r.id)}>查看</span>}
                    />
                </Table>
                <div className="btn-line">
                    <Button type="primary" onClick={_=>window.history.back()}>返回</Button>
                </div>
            </div>
        )
    }
}
