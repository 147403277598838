import React from 'react'
import { Tabs, Spin, message, Modal, Select, DatePicker, Radio, Input, Switch } from 'antd';
import { ProfileOutlined, PayCircleOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { get, post, patch } from 'common/utils.js';
import style from './CouponEditor.module.scss';

const Option = Select.Option;
const TabPane = Tabs.TabPane;

export default class CouponEditor extends React.Component {
    constructor(props) {
        super(props);
        let d = props.data;
        this.state = {
            coupon_id: this.props.id,
            loading: (this.props.id?true:false),
            saving: false,
            data: {
                type: "RANDOM_CODE",
                service_id: (props.serviceList.length >= 1?props.serviceList[0].id:null)
            }
        }
    }

    componentDidMount() {
        if(this.state.coupon_id){
            this.getData();
        }
    }

    getData = ()=>{
        let self = this;
        self.setState({loading: true});
        get(process.env.REACT_APP_SERVER_PATH + 'api/pc/coupon/get_one/'+this.state.coupon_id).then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.errcode === 0) {
                self.setState({
                    data: retData.data.result
                })
            };
        });
    }

    updateData = _=>{
        let self = this;
        self.setState({saving: true});
        let { num, day_begin, day_end, name, status, service_id } = this.state.data;
        // process.env.REACT_APP_SERVER_PATH
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/coupon/update_one/'+this.state.coupon_id, {
            num: parseInt(num),
            day_begin: (day_begin?moment(day_begin).format('YYYY-MM-DD'):null),
            day_end: (day_end?moment(day_end).format('YYYY-MM-DD'):null),
            name: name,
            status: status,
            service_id,
        }).then(retData => {
            self.setState({saving: false});
            if (!!retData.data && retData.data.errcode === 0) {
                message.success('操作成功');
                self.props.changeVisible(false);
            }else{
                message.error('操作失败');
            }
        });
    }

    createData = _=>{
        let self = this;
        self.setState({saving: true});
        let { num, day_begin, day_end, name, status, service_id, type, same_code } = this.state.data;
        // 'http://localhost:8000/'
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/coupon/create_one', {
            num: parseInt(num),
            day_begin: (day_begin?moment(day_begin).format('YYYY-MM-DD'):null),
            day_end: (day_end?moment(day_end).format('YYYY-MM-DD'):null),
            name: name,
            status: status,
            service_id: service_id,
            type: type,
            same_code: (type === 'SAME_CODE' ? same_code : null),
        }).then(retData => {
            self.setState({saving: false});
            if (!!retData.data && retData.data.errcode === 0) {
                message.success('操作成功');
                self.props.changeVisible(false);
            }else{
                message.error('操作失败');
            }
        });
    }

    render() {
        let self = this;
        const { visible, serviceList } = this.props;
        const { loading, saving, data, coupon_id } = this.state;

        return (
            <Modal
                visible={visible}
                className={style["wrapper"]}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
                style={{}}
                width={650}
                title={this.props.id?"修改券码":"新建券码"}
                // footer={null}
                confirmLoading={saving}
                onCancel={_=>this.props.changeVisible(false)}
                onOk={_=>{
                    if(coupon_id){
                        self.updateData();
                    }else{
                        self.createData();
                    }
                }}
            >
                {
                    loading === true &&
                    <Spin loading={true}></Spin>
                }
                {
                    loading === false &&
                    <>
                        <div className="info_line">
                            <div className="label">抵扣项目：</div>
                            <div className="content">
                            {
                                //已创建的券码不可修改餐饮项目
                                coupon_id ?
                                data.service_name
                                :
                                <Select
                                    // className="search-select"
                                    value={data.service_id+''}
                                    style={{ width: 200 }}
                                    defaultValue={data.service_id+''}
                                    onChange={v=>{
                                        data.service_id = v;
                                        self.forceUpdate();
                                    }}
                                >
                                {
                                    serviceList.map(item=>
                                        <Option value={item.id+''} key={item.id}>{item.name}</Option>
                                    )
                                }
                                </Select>
                            }
                            </div>
                        </div>
                        <div className="info_line">
                            <div className="label">券码名称：</div>
                            <div className="content">
                                <Input
                                    style={{width: 200}}
                                    onChange={e=>{
                                    data.name = e.target.value;
                                    self.forceUpdate();
                                }} value={data.name} />
                            </div>
                        </div>
                        <div className="info_line">
                            <div className="label">起止日期：</div>
                            <div className="content">
                                <DatePicker
                                    // showTime={{ format: 'HH:mm:ss' }}
                                    // format="YYYY-MM-DD"
                                    style={{marginRight: 10}}
                                    placeholder="选择开始日期"
                                    value={data.day_begin?moment(data.day_begin):null}
                                    onChange={v=>{
                                        data.day_begin = v;
                                        self.forceUpdate()
                                    }}
                                />
                                至
                                <DatePicker
                                    // showTime={{ format: 'HH:mm:ss' }}
                                    // format="YYYY-MM-DD"
                                    style={{marginLeft: 10}}
                                    placeholder="选择结束日期"
                                    value={data.day_end?moment(data.day_end):null}
                                    onChange={v=>{
                                        data.day_end = v;
                                        self.forceUpdate()
                                    }}
                                />
                            </div>
                        </div>
                        <div className="info_line">
                            <div className="label">券码类型：</div>
                            <div className="content">
                                <Radio.Group disabled={(coupon_id?true:false)} onChange={e=>{
                                    data.type = e.target.value;
                                    self.forceUpdate();
                                }} value={data.type}>
                                    <Radio value="RANDOM_CODE">随机码</Radio>
                                    <Radio value="SAME_CODE">通用码</Radio>
                                </Radio.Group>
                            </div>
                        </div>
                        {
                            data.type === 'SAME_CODE' &&
                            <div className="info_line">
                                <div className="label">统一券码号：</div>
                                <div className="content">
                                    <Input
                                        disabled={(coupon_id?true:false)}
                                        style={{width: 150}}
                                        onChange={e=>{
                                        data.same_code = e.target.value;
                                        self.forceUpdate();
                                    }} value={data.same_code} />
                                </div>
                            </div>
                        }
                        <div className="info_line">
                            <div className="label">券码数量：</div>
                            <div className="content">
                                <Input
                                    style={{width: 150}}
                                    onChange={e=>{
                                    data.num = e.target.value;
                                    self.forceUpdate();
                                }} value={data.num} />
                            </div>
                        </div>
                        <div className="info_line">
                            <div className="label">是否启用：</div>
                            <div className="content">
                                <Switch
                                    checked={(data.status == 1?true:false)}
                                    onChange={v=>{
                                        data.status = (v?1:0);
                                        self.forceUpdate();
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
            </Modal>
        )
    }
}
