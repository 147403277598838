import React from 'react'
import { Tabs, Spin, message, Modal, Select, Descriptions, Button, DatePicker, Input, Badge} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import moment from 'moment';
import { get, post, patch } from 'common/utils.js';
import style from './index.module.scss';

const IDENTITY_ENUM = {
    '1': '身份证',
    '2': '护照',
    '9': '其他类型证件'
}

export default class BasicInfoPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            data: {}
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        let self = this;
        self.setState({ loading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/pc/customer/get_one/'+this.props.customerId).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.result) {
                self.setState({ data: retData.data.result })
            };
        });
    }

    render() {
        let self = this;
        let { data } = this.state;

        let gender_text = '';
        if(data.gender == 1){
            gender_text = '先生';
        }else if(data.gender == 2){
            gender_text = '女士';
        }

        let mobile_text = '';
        if(data.mobile){
            mobile_text = data.mobile;
        }
        if(data.mobile_region){
            mobile_text = '('+data.mobile_region+')'+mobile_text;
        }

        return (
            <div
                className={style["wrapper"]}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <Descriptions column={2} style={{width: '800px'}} bordered>
                    <Descriptions.Item label="姓名">{data.name}</Descriptions.Item>
                    <Descriptions.Item label="称呼">{gender_text}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{mobile_text}</Descriptions.Item>
                    <Descriptions.Item label="电子邮件">{data.email}</Descriptions.Item>
                    <Descriptions.Item label="证件类型">{IDENTITY_ENUM[data.identity_type]}</Descriptions.Item>
                    <Descriptions.Item label="证件号">{data.identity_number}</Descriptions.Item>
                    <Descriptions.Item label="兴趣爱好">{data.hobby_tags && data.hobby_tags.join('，')}</Descriptions.Item>
                </Descriptions>
            </div>
        )
    }
}
