import React, { Component } from 'react'
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom'
import Layout from 'containers/Layout';
import PersonalCenter from 'containers/PersonalCenter';
import UnderConstruction from 'containers/UnderConstruction';
import User from 'containers/PersonMng';
import Role from 'containers/RoleMngSetting';
import Login from "containers/Login";
import SeatMng from 'containers/SeatMng';
import SeatBasicSetting from 'containers/BasicSetting';
import CateringOrderList from 'containers/CateringOrderList';
import CateringList from 'containers/CateringService/CateringList';
import ScanQRCode from 'containers/ScanQRCode/ScanQRCode.js';
import CaterOrderList from 'containers/ScanQRCode/CaterOrderList.js';
import CaterOrderDetails from 'containers/ScanQRCode/CaterOrderDetails.js';
import CaterOrderTodayList from 'containers/CateringOrderList/TodayList.js';
import CustomerList from 'containers/CustomerList';
import CustomerDetails from 'containers/CustomerDetails';
import CouponList from 'containers/CouponList/index.js';

import 'moment/locale/zh-cn'

import './App.scss'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {};
    }
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Redirect exact from="/" to="/System/PersonalCenter" />
                    <Route exact path="/Login" component={Login} />
                    <Route exact path="/ScanQRCode" component={ScanQRCode} />
                    <Layout path="/System" component={Layout}>
                        <Route exact path="/System/UnderConstruction" component={UnderConstruction} />
                        <Route exact path="/System/PersonalCenter" component={PersonalCenter} />
                        <Route exact path="/System/UserList" component={User} />
                        <Route exact path="/System/CustomerList" component={CustomerList} />
                        <Route exact path="/System/Customer" component={CustomerDetails} />
                        <Route exact path="/System/Role" component={Role} />
                        <Route exact path="/System/SeatMng" component={SeatMng} />
                        <Route exact path="/System/CateringService/:id" component={SeatBasicSetting} />
                        <Route exact path="/System/CateringOrders" component={CateringOrderList} />
                        <Route exact path="/System/CateringList" component={CateringList} />
                        <Route exact path="/System/CateringOrdersToday" component={CaterOrderTodayList} />
                        <Route exact path="/System/CouponList" component={CouponList} />
                    </Layout>
                    <Route exact path="/Mobile/CaterOrderList" component={CaterOrderList} />
                    <Route exact path="/Mobile/CaterOrderDetails" component={CaterOrderDetails} />
                </Switch>
            </HashRouter>
        )
    }
}

export default App
