const ORDER_STATUS_ENUM = {
    '3': {
        text: '已核销',
        color: 'green',
    },
    '2': {
        text: '已支付',
        color: 'blue',
    },
    '1': {
        text: '待支付',
        color: 'orange',
    },
    '0': {
        text: '已取消',
        color: '#ccc',
    },
}

const PAY_STATUS_ENUM = {
    '1': {
        text: '待支付',
        color: 'blue',
    },
    '2': {
        text: '已支付',
        color: 'green',
    },
    '3': {
        text: '支付过期',
        color: '#ccc',
    },
    '4': {
        text: '已取消支付',
        color: '#ccc',
    },
    '5': {
        text: '已退款',
        color: '#ccc',
    },
    '6': {
        text: '退款中',
        color: 'orange',
    },
}

export {
    ORDER_STATUS_ENUM,
    PAY_STATUS_ENUM
}