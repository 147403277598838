import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router'
import style from './index.module.scss'
import { Menu, Dropdown, Modal } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';

const Comp = (props) => {
  const { location, history } = props
  const [name, setName] = useState(null);
  useEffect(() => {
    const userInfoStr =
      window.sessionStorage.getItem('USER_INFO') || window.localStorage.getItem('USER_INFO')
    let userInfo = null
    if (typeof userInfoStr === 'string' && userInfoStr !== '') {
      try {
        userInfo = JSON.parse(userInfoStr)
      } catch (err) { }
    }
    if (!!userInfo) {
      const { name, expire_time } = userInfo
      try {
        if (moment.unix(expire_time).isAfter(moment())) {
          if (typeof name === 'string') setName(name)
        }
      } catch (err) { }
    }
  }, [])

  const nameText = useMemo(() => {
    if (typeof name === 'string') {
      return name
    }
    return '(未登录)'
  }, [name])

  const handleMenuClick = ({ key }) => {
    if (key === '1' && location.pathname !== '/System/PersonalCenter') {
      history.push('/System/PersonalCenter')
    } else if (key === '2') {
      Modal.confirm({
        title: '退出登录?',
        content: '',
        onOk() {
          localStorage.clear()
          sessionStorage.clear()
          history.push('/Login')
        }
      })
    }
  }

  return (
    <div className={style['wrapper']} style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
      <div className="left">
        <div className="text">外滩源壹号</div>
      </div>
      <div className="right">
        <Dropdown
          placement="bottomRight"
          overlay={(
            <Menu onClick={handleMenuClick}>
              <Menu.Item
                key="1"
                className={`${location.pathname === '/System/PersonalCenter' ? 'active' : ''}`}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i className="icon personal-icon" />
                  个人中心
                </div>
              </Menu.Item>
              <Menu.Item key="2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i className="icon logout-icon" />
                  退出登录
                </div>
              </Menu.Item>
            </Menu>
          )}
          overlayClassName={style['headerDorpDown']}
          arrow
        >
          <div>
            <UserOutlined className="avatar" />
            <span className="user_name">{nameText}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default withRouter(Comp)
