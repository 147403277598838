import React from 'react'
import { Upload, Input, Button, message, TimePicker, Tabs, Descriptions } from 'antd';
import { LoadingOutlined, PlusOutlined, EditFilled } from '@ant-design/icons';
import { PhotoSwipe } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import style from './index.module.scss';
import { getBase64, beforeUpload, textValue } from './utils';
import { getToken, get, post } from 'common/utils.js';
import moment from 'moment';
import DomResize from 'components/DomResize';
import SingleImg from './SingleImg';
import PopupWrapper from 'components/PopupWrapper.js';

const { TextArea } = Input;
const { TabPane } = Tabs;

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl: null,
            swiperLoading: false,
            desc: textValue,
            picWidth: null,
            coverImgName: null,
            service_id: props.match.params.id,
            data: {
                'extra_info': {}
            },
            mode: 'DISPLAY',
            coverLoading: false,
            saving: false,

            activeImgIndex: 0,
            imageList: [],
        };
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.service_id != nextProps.match.params.id){
            this.setState({
                service_id: nextProps.match.params.id,
                mode: "DISPLAY",
            }, _=>this.getData())
        }
    }

    componentDidMount() {
        if(this.state.service_id == '-1'){
            this.setState({
                mode: "EDIT",
                data: {
                    name: "",
                    name_en: "",
                    sort_index: 0,
                    extra_info: {
                        brief_info: "",
                        detail_info: "",
                        min_rsvd: 2,
                        max_rsvd: 20,
                        cover_image: null,
                        details_image: null,
                        upper_image: null,
                        swiper_images: [],
                    }
                }
            })
        }else{
            this.getData();
        }
    }

    getData = ()=>{
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/service_info/get_one/'+this.state.service_id).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.errcode === 0) {
                this.setState({data: retData.data.result})
            };
        });
    }

    checkAndResetRsvdLimit = (extra_info)=>{
        let isNum = (s)=>{
            if(s === ""){
                return false;
            }
            if(s!=null){
                var r,re;
                re = /\d*/i;
                r = s.match(re);
                return (r==s)?true:false;
            }
            return false;
        }
        let min_rsvd = extra_info.min_rsvd + ""
        let max_rsvd = extra_info.max_rsvd + ""
        if(!isNum(min_rsvd) || !isNum(max_rsvd) || min_rsvd < 1){
            message.error("每单订座数请填写大于1的正整数");
            return false;
        }
        min_rsvd = parseInt(min_rsvd)
        max_rsvd = parseInt(max_rsvd)
        if(min_rsvd > max_rsvd){
            message.error("每单订座数填写错误，起订数需小于等于订座上限");
            return false;
        }
        extra_info.min_rsvd = min_rsvd;
        extra_info.max_rsvd = max_rsvd;
        return true;
    }

    saveData = ()=>{
        if(!this.checkAndResetRsvdLimit(this.state.data.extra_info)){
            return;
        }
        let self = this;
        this.setState({saving: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/service_info/update_one/'+this.state.service_id, {
            extra_info: this.state.data.extra_info,
            name: this.state.data.name,
            name_en: this.state.data.name_en,
            sort_index: parseInt(this.state.data.sort_index),
        }).then(retData => {
            self.setState({ saving: false });
            if (!!retData.data && retData.data.errcode === 0) {
                message.success('操作成功');
                self.setState({mode: "DISPLAY"});
            };
        });
    }

    createData = ()=>{
        if(!this.checkAndResetRsvdLimit(this.state.data.extra_info)){
            return;
        }
        let self = this;
        this.setState({saving: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/service_info/create_one', {
            extra_info: this.state.data.extra_info,
            name: this.state.data.name,
            name_en: this.state.data.name_en,
            sort_index: parseInt(this.state.data.sort_index),
        }).then(retData => {
            self.setState({ saving: false });
            if (!!retData.data && retData.data.errcode === 0) {
                message.success('操作成功');
                self.props.history.replace('/System/CateringService/'+retData.data.result.id)
            }else{
                message.error('操作失败');
            }
        });
    }

    handleCoverImgChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
        }else{
            this.setState({ loading: false });
        }

        if (info.file.status === 'done') {
            let filename = info.file?.response?.img_name ?? null;
            this.state.data.extra_info.cover_image = filename;
            this.forceUpdate();
            message.success("上传成功");
        }
        if (info?.file?.status === 'error') {
            message.warning("上传失败,请重试!");
        };
    };

    handleSwiperImgChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ swiperLoading: true });
        }else{
            this.setState({ swiperLoading: false });
        }
        if (info.file.status === 'done') {
            let filename = info.file?.response?.img_name ?? null;
            this.state.data.extra_info.swiper_images = this.state.data.extra_info.swiper_images.concat([filename]);
            this.forceUpdate();
            message.success("上传成功");
        }
        if (info?.file?.status === 'error') {
            message.warning("上传失败,请重试!");
        };
    };

    onResize = (conf) => {
        let picWidth = null;
        if (typeof conf?.width === 'number' && !isNaN(conf?.width)) {
            picWidth = conf.width * 0.238;
        };
        this.setState({ picWidth });
    };

    render() {
        let self = this;
        const { loading, coverLoading, imageUrl, desc, picWidth, swiperLoading, swiperImgList, coverImgName, data, mode, saving,
            isPhotoSwipeOpen, activeImgIndex, imageList } = this.state;
        let { extra_info } = data;

        const uploadButton = (
            <div>
                {coverLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>上传</div>
            </div>
        );

        return (
            <div className={style['wrapper']}>
{/*                <PopupWrapper
                    display={isPhotoSwipeOpen}
                    callBack={_ => self.setState({ isPhotoSwipeOpen: false })}
                ></PopupWrapper>*/}
                <PhotoSwipe
                    isOpen={isPhotoSwipeOpen}
                    options={{ shareEl: false, index: activeImgIndex, clickToCloseNonZoomable: false, closeOnScroll: false }}
                    imageLoadComplete={(instance, index, item) => {
                        console.log('item.w/h: ', item.w, item.h);
                        if (item.w < 1 || item.h < 1) { // unknown size
                            let img = new Image();
                            img.onload = function () { // will get size after load
                                console.log('img.onload');
                                item.w = this.naturalWidth; // set image width
                                item.h = this.naturalHeight; // set image height
                                if (instance) {
                                    console.log('instance.invalidateCurrItems');
                                    // instance.invalidateCurrItems(); // reinit Items
                                    instance.updateSize(true); // reinit Items
                                }
                            }
                            img.src = item.src; // let's download image
                        } else {
                            // instance.invalidateCurrItems();
                            // instance.updateSize(true);
                        }
                    }}
                    items={imageList.map((filename, index) => {
                        return {
                            w: 0,
                            h: 0,
                            src: process.env.REACT_APP_SERVER_PATH + `api/img/download/${filename}`,
                            title: filename
                        }
                    })}
                    onClose={() => this.setState({isPhotoSwipeOpen: false})}
                />
                <div className="content">
                    <div className="top">
                        <div className="left">
                            <div className="upload_wrapper">
                                {
                                    extra_info.cover_image &&
                                    <img
                                        className="cover_image"
                                        src={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + extra_info.cover_image}
                                        style={{ width: '100%' }}
                                    />
                                }
{/*                                <Upload
                                    // name="files"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    headers={{
                                        authorization: getToken(),
                                    }}
                                    data={{'module_name': 'catering'}}
                                    action={process.env.REACT_APP_SERVER_PATH + "api/img/upload"}
                                    beforeUpload={beforeUpload}
                                    onChange={this.handleCoverImgChange}
                                >
                                    {(extra_info.cover_image && !coverLoading) ? <img className="cover_image" src={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + extra_info.cover_image} alt="cover" style={{ width: '100%' }} /> : uploadButton}
                                </Upload>*/}
                            </div>
                            {
                                mode === "EDIT" &&
                                <Upload
                                    // name="files"
                                    // listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    headers={{
                                        authorization: getToken(),
                                    }}
                                    data={{'module_name': 'catering'}}
                                    action={process.env.REACT_APP_SERVER_PATH + "api/img/upload"}
                                    beforeUpload={beforeUpload}
                                    onChange={this.handleCoverImgChange}
                                >
                                    <Button icon={<EditFilled />} className="text" style={{marginTop: 15}}>点击修改封面图</Button>
                                </Upload>
                            }
                        </div>
                        <div className="right">
                            {
                                mode === 'DISPLAY' &&
                                <div className="top">
                                    <div className="title">{data.name} ｜ {data.name_en}</div>
                                    <div className="title_btns">
                                        <Button type="default" style={{ marginLeft: 'auto' }} onClick={()=>window.history.back()}>返回</Button>
                                        {
                                            data.status >= 0 &&
                                            <Button type="primary" style={{ marginLeft: '15px' }} onClick={()=>this.setState({mode: "EDIT"})}>编辑</Button>
                                        }
                                    </div>
                                </div>
                            }
                            {
                                mode === 'EDIT' &&
                                <div className="top">
                                    <div className="title_input">
                                        <div style={{width: 100}}>中文名：</div>
                                        <Input
                                            style={{width: 250}}
                                            value={data.name}
                                            onChange={e=>{
                                                data.name = e.target.value;
                                                self.forceUpdate()
                                            }}
                                        />
                                        <div style={{width: 100, marginLeft: 20}}>英文名：</div>
                                        <Input
                                            style={{width: 250}}
                                            value={data.name_en}
                                            onChange={e=>{
                                                data.name_en = e.target.value;
                                                self.forceUpdate()
                                            }}
                                        />
                                    </div>
                                    <div className="title_btns">
                                        <Button
                                            type="default"
                                            style={{ marginLeft: 'auto' }}
                                            onClick={()=>{
                                                if(self.state.service_id == '-1'){
                                                    window.history.back()
                                                }else{
                                                    this.setState({mode: 'DISPLAY'}, ()=>this.getData());
                                                }
                                            }}
                                        >取消</Button>
                                        <Button
                                            type="primary"
                                            style={{ marginLeft: 15 }}
                                            onClick={()=>{
                                                if(self.state.service_id == '-1'){
                                                    self.createData();
                                                }else{
                                                    self.saveData();
                                                }
                                            }}
                                            loading={saving}
                                        >保存</Button>
                                    </div>
                                </div>
                            }
                            {
                                mode === 'DISPLAY' &&
                                <div className="second">
                                    <div className="title" style={{width: '150px', flexGrow: 0, flexShrink: 0}}>时间：{extra_info.time_begin} - {extra_info.time_end}</div>
                                    <div>序号：{data.sort_index}</div>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div>每单订座数：{extra_info.min_rsvd} ~ {extra_info.max_rsvd}</div>
                                </div>
                            }
                            {
                                mode === 'EDIT' &&
                                <div className="second">
                                    <div className="title_input">
                                        <div>时&nbsp;&nbsp;&nbsp;&nbsp;间：</div>
                                        <TimePicker
                                            style={{marginLeft: 3, wdith: '100px'}}
                                            value={extra_info.time_begin ? moment(extra_info.time_begin, 'HH:mm'):null}
                                            onChange={v=>{
                                                extra_info.time_begin = v.format('HH:mm');
                                                self.forceUpdate();
                                            }}
                                            format='HH:mm'
                                        />
                                        <div> &nbsp;&nbsp;-&nbsp;&nbsp; </div>
                                        <TimePicker
                                            style={{wdith: '100px'}}
                                            value={extra_info.time_end ? moment(extra_info.time_end, 'HH:mm'):null}
                                            onChange={v=>{
                                                extra_info.time_end = v.format('HH:mm');
                                                self.forceUpdate();
                                            }}
                                            format='HH:mm'
                                        />
                                        <div style={{marginLeft: 20}}>序号：</div>
                                        <Input
                                            style={{width: 45}}
                                            value={data.sort_index}
                                            onChange={e=>{
                                                data.sort_index = e.target.value;
                                                self.forceUpdate()
                                            }}
                                        />
                                        <div style={{marginLeft: 20}}>每单订座数：</div>
                                        <Input
                                            style={{width: 45}}
                                            value={extra_info.min_rsvd}
                                            onChange={e=>{
                                                extra_info.min_rsvd = e.target.value;
                                                self.forceUpdate()
                                            }}
                                        />
                                        &nbsp;&nbsp;~&nbsp;&nbsp;
                                        <Input
                                            style={{width: 45}}
                                            value={extra_info.max_rsvd}
                                            onChange={e=>{
                                                extra_info.max_rsvd = e.target.value;
                                                self.forceUpdate()
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                mode === 'EDIT' &&
                                <div className="brief_line_edit">
                                    <div className="info_name">简&nbsp;&nbsp;&nbsp;&nbsp;介：</div>
                                    <TextArea
                                        // rows={5}
                                        className="info_content"
                                        value={extra_info.brief_info}
                                        onChange={e=>{
                                            extra_info.brief_info = e.target.value;
                                            self.forceUpdate();
                                        }}
                                    />
                                </div>
                            }
                            {
                                mode === 'DISPLAY' &&
                                <div className="brief_line_display">
                                    <div className="info_name">简介：</div>
                                    <pre className="info_display">{extra_info.brief_info}</pre>
                                </div>
                            }
                            <div className="third">详情与细则：</div>
                            {
                                mode === 'EDIT' &&
                                <TextArea
                                    // rows={5}
                                    className="textarea_comp"
                                    value={extra_info.detail_info}
                                    onChange={e=>{
                                        extra_info.detail_info = e.target.value;
                                        self.forceUpdate();
                                    }}
                                />
                            }
                            {
                                mode === 'DISPLAY' &&
                                <pre className="detail_info">{extra_info.detail_info}</pre>
                            }
                        </div>
                    </div>
                    <div className="bottom">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="轮播图片" key="1">
                                <div className="pics">
                                    <div style={{ height: 0, position: 'absolute', top: 0, left: 0, right: 0 }}>
                                        <DomResize mountedCall={true} onResize={this.onResize} />
                                    </div>
                                    {
                                        (typeof picWidth === "number" && !isNaN(picWidth) && mode === 'EDIT') && (
                                            <div className="upload_wrapper" style={{ width: picWidth, height: picWidth * 0.6 }}>
                                                <Upload
                                                    // name="avatar"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    headers={{
                                                        authorization: getToken(),
                                                    }}
                                                    data={{'module_name': 'catering'}}
                                                    action={process.env.REACT_APP_SERVER_PATH + "api/img/upload"}
                                                    beforeUpload={beforeUpload}
                                                    onChange={this.handleSwiperImgChange}
                                                >
                                                    <div style={{ width: picWidth, height: picWidth * 0.6 }} className="inner_content">
                                                        {swiperLoading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: 60, color: 'rgba(0, 0, 0, 0.45)' }} />}
                                                        <div style={{ marginTop: 8 }}>上传图片</div>
                                                        <div style={{ marginTop: 8 }}>只支持.jpg / .png格式</div>
                                                    </div>
                                                </Upload>
                                            </div>
                                        )
                                    }
                                    {
                                        (extra_info.swiper_images ?? [])
                                            .map((item, index) => {
                                                return (
                                                    <div className="upload_wrapper" style={{ width: picWidth, height: picWidth * 0.6 }} key={index}>
                                                        <SingleImg
                                                            key={item}
                                                            imageUrl={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + item}
                                                            allowDelete={mode === 'EDIT'}
                                                            delItem={_=>{
                                                                extra_info.swiper_images.splice(index, 1);
                                                                self.forceUpdate();
                                                            }}
                                                            displayPhotoSwipe={_=>{
                                                                self.setState({
                                                                    isPhotoSwipeOpen: true,
                                                                    activeImgIndex: index,
                                                                    imageList: extra_info.swiper_images,
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tab="其他图片" key="2">
                                <Descriptions
                                    className="other_images"
                                    layout="vertical"
                                    column={1}
                                    bordered
                                >
                                    <Descriptions.Item label={
                                        <div>
                                            简介背景图
                                            {
                                                mode === "EDIT" &&
                                                <Upload
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    headers={{
                                                        authorization: getToken(),
                                                    }}
                                                    data={{'module_name': 'catering'}}
                                                    action={process.env.REACT_APP_SERVER_PATH + "api/img/upload"}
                                                    beforeUpload={beforeUpload}
                                                    onChange={info => {
                                                        if (info.file.status === 'done') {
                                                            let filename = info.file?.response?.img_name ?? null;
                                                            this.state.data.extra_info.upper_image = filename;
                                                            this.forceUpdate();
                                                            message.success("上传成功");
                                                        }
                                                        if (info?.file?.status === 'error') {
                                                            message.warning("上传失败,请重试!");
                                                        };
                                                    }}
                                                >
                                                    <Button type="link">修改</Button>
                                                </Upload>
                                            }
                                        </div>
                                    }>
                                    {
                                        extra_info.upper_image ?
                                        <img
                                            className="other_image"
                                            src={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + extra_info.upper_image}
                                            alt="cover"
                                            style={{cursor: 'pointer'}}
                                            onClick={_=>{
                                                self.setState({
                                                    isPhotoSwipeOpen: true,
                                                    activeImgIndex: 0,
                                                    imageList: [extra_info.upper_image],
                                                })
                                            }}
                                            // style={{ width: '100%' }}
                                        />
                                        :
                                        <br/>
                                    }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={
                                        <div>
                                            详细内容图
                                            {
                                                mode === "EDIT" &&
                                                <Upload
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    headers={{
                                                        authorization: getToken(),
                                                    }}
                                                    data={{'module_name': 'catering'}}
                                                    action={process.env.REACT_APP_SERVER_PATH + "api/img/upload"}
                                                    beforeUpload={beforeUpload}
                                                    onChange={info => {
                                                        if (info.file.status === 'done') {
                                                            let filename = info.file?.response?.img_name ?? null;
                                                            this.state.data.extra_info.details_image = filename;
                                                            this.forceUpdate();
                                                            message.success("上传成功");
                                                        }
                                                        if (info?.file?.status === 'error') {
                                                            message.warning("上传失败,请重试!");
                                                        };
                                                    }}
                                                >
                                                    <Button type="link">修改</Button>
                                                </Upload>
                                            }
                                        </div>
                                    }>
                                    {
                                        extra_info.details_image ?
                                        <img
                                            className="other_image"
                                            src={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + extra_info.details_image}
                                            alt="cover"
                                            style={{cursor: 'pointer'}}
                                            onClick={_=>{
                                                self.setState({
                                                    isPhotoSwipeOpen: true,
                                                    activeImgIndex: 0,
                                                    imageList: [extra_info.details_image],
                                                })
                                            }}
                                            // style={{ width: '100%' }}
                                        />
                                        :
                                        <br/>
                                    }
                                    </Descriptions.Item>
                                    <Descriptions.Item label={
                                        <div>
                                            首页底部卡片背景图
                                            {
                                                mode === "EDIT" &&
                                                <Upload
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    headers={{
                                                        authorization: getToken(),
                                                    }}
                                                    data={{'module_name': 'catering'}}
                                                    action={process.env.REACT_APP_SERVER_PATH + "api/img/upload"}
                                                    beforeUpload={beforeUpload}
                                                    onChange={info => {
                                                        if (info.file.status === 'done') {
                                                            let filename = info.file?.response?.img_name ?? null;
                                                            this.state.data.extra_info.card_image = filename;
                                                            this.forceUpdate();
                                                            message.success("上传成功");
                                                        }
                                                        if (info?.file?.status === 'error') {
                                                            message.warning("上传失败,请重试!");
                                                        };
                                                    }}
                                                >
                                                    <Button type="link">修改</Button>
                                                </Upload>
                                            }
                                        </div>
                                    }>
                                    {
                                        extra_info.card_image ?
                                        <img
                                            className="other_image"
                                            src={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + extra_info.card_image}
                                            alt="cover"
                                            style={{cursor: 'pointer'}}
                                            onClick={_=>{
                                                self.setState({
                                                    isPhotoSwipeOpen: true,
                                                    activeImgIndex: 0,
                                                    imageList: [extra_info.card_image],
                                                })
                                            }}
                                            // style={{ width: '100%' }}
                                        />
                                        :
                                        <br/>
                                    }
                                    </Descriptions.Item>
                                </Descriptions>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}
