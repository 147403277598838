import { message } from 'antd';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

const textValue = "1. BRUNCH早午餐每位包含的餐食内容有：XXXXX、XXXXX、XXXX、XXXX、XXX和XX。其中含3款免费酒水，套餐外酒水饮品额外付费。\n2. 用餐时段为 11:30-15:00 ，共3.5小时，15:00为最晚离场时间。\n3. 餐位预订实行全款预订制，距离预订日期大于3天取消可全额退款，  小于等于3天取消或过期未消费则不予退款，阁下可致电客服另行安排时间消费。\n4. 餐位预订2位起订";

export {
    getBase64,
    beforeUpload,
    textValue,
};