import React from 'react'
import { Tabs, Spin, message, Modal, Select, Button, Switch, Input } from 'antd';
import { get, post, patch } from 'common/utils.js';
import style from './DayInfoEditor.module.scss';
import moment from 'moment';

const Option = Select.Option;
const TabPane = Tabs.TabPane;

const WEEK_DAY_NAMES = {
    '0': "周日",
    '1': "周一",
    '2': "周二",
    '3': "周三",
    '4': "周四",
    '5': "周五",
    '6': "周六",
};

export default class DayInfoEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            catering_list: props.data.catering_list,
            day: props.data.day
        }
    }

    componentWillMount() {
        // this.getData(this.props.id)
    }

    // type = SINGLE or MULTIPLE
    saveDayInfo = (type)=>{
        let c_list = this.state.catering_list;
        for(let i = 0;i<c_list.length;i++){
            if(c_list[i].total_num < 0){
                message.error('总数不可小于零');
                return;
            }else if(c_list[i].total_num < c_list[i].rsvd_num){
                message.error('总数不可小于已预订数量');
                return;
            }
        }

        let self = this;
        let day = this.state.day;
        let day_list = [];
        if(type === "SINGLE"){
            day_list = [moment(day).format('YYYY-MM-DD')];
        }else if(type === "MULTIPLE"){
            let d = moment(day).format('YYYY-MM-01');
            while(moment(day).format('MM') === moment(d).format('MM')){
                if(moment(d).day() == moment(day).day()){
                    day_list.push(moment(d).format('YYYY-MM-DD'));
                }
                d = moment(d).add(1, 'days');
            }
        }
        // console.log(day_list);
        // console.log(this.state.catering_list);
        // return;
        let catering_list = this.state.catering_list.map(item=>{
            let price = null;
            if(typeof(item.price) === 'string' && item.price.trim() !== ''){
                price = parseFloat(item.price);
            }
            return {
                total_num: item.total_num,
                price: item.price,
                service_id: item.service_id,
                status: item.status,
                avbl_days: parseInt(item.avbl_days),
            }
        });
        self.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/save_day_info', {
            day_list: day_list,
            catering_list: catering_list,
        }).then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.errcode === 0) {
                message.success('操作成功');
                self.props.onRefresh && self.props.onRefresh()
            }else{
                message.error("保存失败");
            }
        });
    }

    get_avbl_day_text = avbl_days =>{
        if(avbl_days !== null && avbl_days !== undefined && avbl_days !== ''){
            return "（"+moment(this.state.day).subtract(avbl_days, 'days').format("YYYY年M月D日")+"可预订）";
        }
    }

    render() {
        let self = this;
        const { visible } = this.props;
        const { loading, catering_list, day } = this.state;
        let weekDayName = WEEK_DAY_NAMES[moment(day).day()+''];

        return (
            <Modal
                visible={visible}
                width={640}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
                style={{}}
                title={moment(this.state.day).format('YYYY年M月D日')+'详情配置'}
                onCancel={_=>this.props.changeVisible(false)}
                footer={([
                    <Button key="1" onClick={_=>this.props.changeVisible(false)}>取消</Button>
                    ,
                    <Button type="primary" key="2" onClick={()=>this.saveDayInfo('SINGLE')}>仅配置该日期</Button>
                    ,
                    <Button type="primary" key="3" onClick={()=>this.saveDayInfo('MULTIPLE')}>配置本月所有：{weekDayName}</Button>
                ])}
            >
                {
                    loading === true &&
                    <Spin loading={true}></Spin>
                }
                {
                    catering_list &&
                    <div className={style["wrapper"]}>
                        {
                            catering_list.map((item, index)=>
                                [
                                <div className="line"></div>
                                ,
                                <div className="item">
                                    <div className="title_row">
                                        <div className="title">{item.name}</div>
                                        <Switch checked={(item.status?true:false)} onChange={checked=>{
                                            if(checked){
                                                item.status = 1;
                                            }else{
                                                item.status = 0;
                                            }
                                            self.forceUpdate();
                                        }} />
                                    </div>
                                    <div className="content_row">
                                        <div className="title">单价（元）：</div>
                                        <Input
                                            value={item.price}
                                            style={{ width: 100 }}
                                            onChange={e=>{
                                                item.price = e.target.value;
                                                self.forceUpdate();
                                            }}
                                        />
                                        <div className="title title_2">总数量：</div>
                                        <Input
                                            value={item.total_num}
                                            style={{ width: 100 }}
                                            onChange={e=>{
                                                const { value } = e.target;
                                                const reg = /^[0-9]*[1-9][0-9]*$/;
                                                if ((!isNaN(value) && reg.test(value)) || value === '0') {
                                                    item.total_num = parseInt(value);
                                                    self.forceUpdate();
                                                }else if(value === ''){
                                                    item.total_num = 0;
                                                    self.forceUpdate();
                                                }
                                            }}
                                        />
                                        <div className="rsvd_value">已预订：{item.rsvd_num}</div>
                                        <div className="remain_value">剩余：{(item.total_num - item.rsvd_num)}</div>
                                        {/*<Button type="primary" style={{ marginLeft: "auto" }}>确定</Button>*/}
                                    </div>
                                    <div className="content_row">
                                        <div className="title">提前：</div>
                                        <Input
                                            value={item.avbl_days}
                                            style={{ width: 50, marginLeft: "42px" }}
                                            onChange={e=>{
                                                const { value } = e.target;
                                                const reg = /^[0-9]*[1-9][0-9]*$/;
                                                if ((!isNaN(value) && reg.test(value)) || value === '0') {
                                                    item.avbl_days = parseInt(value);
                                                    self.forceUpdate();
                                                }else if(value === ''){
                                                    item.avbl_days = 0;
                                                    self.forceUpdate();
                                                }
                                            }}
                                        />
                                        <div style={{marginLeft: "12px"}}>
                                            天可预订
                                            <span style={{color: "#1890ff", marginLeft: '4px'}}>
                                                {self.get_avbl_day_text(item.avbl_days)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                ]
                            )
                        }
                    </div>
                }
            </Modal>
        )
    }
}
