import React from 'react'
import { Menu, Dropdown, Table, Button, message, Modal, Badge, Select, Switch } from 'antd';
import { DeleteOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import TopBreadcrumb from 'components/TopBreadCrumb'
import { get, post } from 'common/utils.js';
import style from './CateringList.module.scss';

const Column = Table.Column;
const Option = Select.Option;

const breadcrumbList = [{ name: '餐饮' }, { name: '基础信息' }]

export default class CateringList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            loading: false,
            editorVisible: false,
            editorData: {},
            displayType: "CURRENT",
        }
    }

    componentDidMount() {
        this.getDataList();
    }

    getDataList = () => {
        let self = this;
        self.setState({ loading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/service_info/get_list').then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results})
            };
        });
    }

    updateStatus = (item, status) => {
        let self = this;
        item.loading = true;
        self.forceUpdate();
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/service_info/update_one/' + item.id, {
            status: status
        }).then(retData => {
            item.loading = false;
            if (retData.data && retData.data.errcode === 0) {
                item.status = status;
                message.success('操作成功');
            }else {
                message.error('操作失败');
            }
            self.forceUpdate();
            this.getDataList();
        });
    }

    render() {
        let self = this;
        let { editorVisible, editorData, service_id, dataList, displayType } = this.state;
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                <div className="down_content">
                    <div className="tool_bar">
                        <Select defaultValue="CURRENT" style={{ width: 150 }} onChange={v=>this.setState({displayType: v})}>
                            <Option value="CURRENT">当前餐饮服务</Option>
                            <Option value="HISTORY">历史餐饮服务</Option>
                        </Select>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={_=>this.props.history.push('/System/CateringService/-1')}
                        >添加</Button>
                    </div>
                    <div className="main_content">
                        {
                            dataList.filter(item=>{
                                if(displayType == 'CURRENT'){
                                    return item.status >= 0;
                                }else{
                                    return item.status == -1;
                                }
                            }).map(item=>
                            <div className="item_card" key={'service-'+item.id}>
                                <img
                                    className="left_img"
                                    src={process.env.REACT_APP_SERVER_PATH + "api/img/download/" + item.extra_info.cover_image}
                                    onClick={_=>this.props.history.push('/System/CateringService/'+item.id)}
                                />
                                <div className="right_info">
                                    <div className="info_line" style={{justifyContent: 'space-between'}}>
                                        <div
                                            className="name"
                                            onClick={_=>this.props.history.push('/System/CateringService/'+item.id)}
                                        >
                                            {item.name}<br/>{item.name_en}
                                        </div>
                                        {
                                            item.status >= 0 &&
                                            <Switch
                                                checked={(item.status === 1?true:false)}
                                                onChange={v=>this.updateStatus(item, (v?1:0))}
                                                style={{marginTop: 2}}
                                            />
                                        }
                                    </div>
                                    <div className="info_line" style={{marginTop: '20px'}}>时间：{item.extra_info.time_begin} - {item.extra_info.time_end}</div>
                                    <div className="info_line" style={{marginTop: '8px'}}>简介：{item.extra_info.brief_info}</div>
{/*                                    <Button
                                        type="link"
                                        danger
                                        style={{flexGrow: 0, width: '50px', bottom: '2px', position: 'absolute', right: '84px'}}
                                        onClick={_=>this.props.history.push('/System/CateringService/'+item.id)}
                                    >删除</Button>
                                    <Button
                                        type="primary"
                                        style={{flexGrow: 0, width: '70px', bottom: '2px', position: 'absolute', right: '3px'}}
                                        onClick={_=>this.props.history.push('/System/CateringService/'+item.id)}
                                    >编辑</Button>*/}
                                    {
                                        item.status >= 0 &&
                                        <Dropdown.Button
                                            type="primary"
                                            icon={<DownOutlined />}
                                            style={{flexGrow: 0, bottom: '2px', position: 'absolute', right: '3px'}}
                                            onClick={_=>this.props.history.push('/System/CateringService/'+item.id)}
                                            overlay={
                                                <Menu onClick={_=>{
                                                    Modal.confirm({
                                                        content: `确认永久下线吗？永久下线后无法再启用。`,
                                                        onOk: () => {
                                                            self.updateStatus(item, -1)
                                                        },
                                                    });
                                                }}>
                                                    <Menu.Item style={{color: 'red'}} key="1" icon={<DeleteOutlined />}>
                                                        永久下线
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                        >编辑</Dropdown.Button>
                                    }
                                </div>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
}
