import React from 'react'
import { Tabs, Spin, message, Modal, Select, Descriptions, Button, Table, Checkbox, Badge } from 'antd';
import { ProfileOutlined, PayCircleOutlined, UserOutlined } from '@ant-design/icons';
import { get, post, patch } from 'common/utils.js';
import { PAY_STATUS_ENUM } from '../constrants.js';
import { getMyPermissions } from 'common/AuthPermission.js';
import style from './OrderPayPane.module.scss';

const Option = Select.Option;
const TabPane = Tabs.TabPane;
const Column = Table.Column;

export default class OrderPayPane extends React.Component {
    constructor(props) {
        super(props);
        // let d = props.data;
        this.state = {
            myPermissions: getMyPermissions() ?? [],
            showConfirm: false,
            confirmLoading: false,
            cancelChecked: true,
        }
    }

    componentDidMount() {
    }

    submitRefund = (id, cancelOrder) => {
        let self = this;
        self.setState({confirmLoading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/submit_refund/'+id,{
            cancel_order: cancelOrder
        }).then(retData => {
            self.setState({confirmLoading: false});
            if (retData.data && retData.data.errcode === 0) {
                self.setState({showConfirm: false});
                self.props.onRefresh && self.props.onRefresh();
                message.success('操作成功');
            }else if(retData.data && retData.data.errcode !== 0){
                if(retData.data.msg){
                    message.error(retData.data.msg);
                }else{
                    message.error('操作失败');
                }
            }
        });
    }

    render() {
        let self = this;
        const { visible, c_order_info, pay_order_info } = this.props;
        const { showConfirm, confirmLoading, cancelChecked, myPermissions } = this.state;
        // const {  } = this.state;
        let orig_sum = 0;
        let extra_data = {};
        if(c_order_info.extra_data != null){
            extra_data = c_order_info.extra_data;
        }
        if(c_order_info.rsvd_num !== null &&
            c_order_info.rsvd_num !== undefined &&
            extra_data.unit_price !== null &&
            extra_data.unit_price !== undefined){
            orig_sum = extra_data.unit_price * c_order_info.rsvd_num;
        }
        let coupon_list = [];
        if(extra_data.coupon_list){
            coupon_list = extra_data.coupon_list.map(item=>{
                return {
                    'name': item.name,
                    'code': item.name,
                    'price': 0 - extra_data.unit_price,
                    'num': 1,
                    'sum_price': 0 - extra_data.unit_price,
                }
            })
        }

        return (
            <div
                className={style["wrapper"]}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <Descriptions column={2} size="small" bordered>
                    <Descriptions.Item label="订  单  号">{c_order_info.sn}</Descriptions.Item>
                    <Descriptions.Item label="下单时间">{c_order_info.create_time}</Descriptions.Item>
                    <Descriptions.Item label="支付流水号">{pay_order_info.ext_sn}</Descriptions.Item>
                    <Descriptions.Item label="付款时间">{pay_order_info.pay_time}</Descriptions.Item>
                    <Descriptions.Item label="支付状态">
                        <Badge color={(pay_order_info.status !== null && PAY_STATUS_ENUM[pay_order_info.status + '']) ? PAY_STATUS_ENUM[pay_order_info.status + ''].color : null} />
                        {pay_order_info.status !== null && PAY_STATUS_ENUM[pay_order_info.status + ''].text}
                    </Descriptions.Item>
                </Descriptions>
                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    style={{marginTop: 4}}
                    dataSource={[
                        {
                            'id': 1,
                            'name': extra_data.s_name,
                            'unit_price': extra_data.unit_price,
                            'rsvd_num': c_order_info.rsvd_num,
                            'sum_price': c_order_info.rsvd_num * extra_data.unit_price
                        }
                    ]}
                >
                    <Column
                        title='预订项目'
                        key='name'
                        width={245}
                        dataIndex='name'
                    />
                    <Column
                        title='单价'
                        key='unit_price'
                        align='center'
                        width={80}
                        dataIndex='unit_price'
                        render={(text)=><span>￥{text}</span>}
                    />
                    <Column
                        title='数量'
                        key='rsvd_num'
                        align='center'
                        width={80}
                        dataIndex='rsvd_num'
                    />
                    <Column
                        title='小计'
                        key='sum_price'
                        align='center'
                        width={80}
                        dataIndex='sum_price'
                        render={(text)=><span>￥{text}</span>}
                    />
                </Table>
                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    locale={{emptyText:"无活动抵扣"}}
                    dataSource={coupon_list}
                >
                    <Column
                        title='活动抵扣'
                        key='name'
                        width={245}
                        dataIndex='name'
                    />
                    <Column
                        title='优惠'
                        key='price'
                        align='center'
                        width={80}
                        dataIndex='price'
                        render={(text)=><span>￥{text}</span>}
                    />
                    <Column
                        title='数量'
                        key='num'
                        align='center'
                        width={80}
                        dataIndex='num'
                    />
                    <Column
                        title='小计'
                        key='sum_price'
                        align='center'
                        width={80}
                        dataIndex='sum_price'
                        render={(text)=><span>￥{text}</span>}
                    />
                </Table>
                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    showHeader={false}
                    dataSource={[
                        {
                            'id': 1,
                            'name': '应付：',
                            'amount': (pay_order_info.amount ? pay_order_info.amount : 0)
                        },{
                            'id': 2,
                            'name': '实付：',
                            'amount': (pay_order_info.amount ? pay_order_info.amount : 0)
                        }
                    ]}
                >
                    <Column
                        key='name'
                        width={405}
                        dataIndex='name'
                        render={(text)=><span style={{fontWeight: 'bold'}}>{text}</span>}
                    />
                    <Column
                        key='amount'
                        width={80}
                        align='center'
                        dataIndex='amount'
                        render={(text)=><span style={{fontWeight: 'bold'}}>￥{text}</span>}
                    />
                </Table>
                <div className="btn-line" style={{justifyContent: 'space-around'}}>
                    <Button onClick={()=>{
                        self.props.onClose && self.props.onClose();
                    }}>关闭</Button>
                    {
                        myPermissions.includes('CATER/ORDER/MNG/REFUND') &&
                        (pay_order_info.status == 2) &&
                        (c_order_info.status == 0 || c_order_info.status == 2 || c_order_info.status == 3) && // 已取消、已支付和已核销的订单可以退款
                        <Button
                            type="primary"
                            onClick={()=>self.setState({showConfirm: true, cancelChecked: true})}
                        >主动退款</Button>
                    }
                </div>
                <Modal
                    // zIndex={999}
                    visible={showConfirm}
                    confirmLoading={confirmLoading}
                    style={{}}
                    // width={800}
                    // title="确认退款吗？"
                    title={null}
                    // footer={null}
                    onOk={_=>self.submitRefund(c_order_info.id, cancelChecked)}
                    onCancel={_=>self.setState({showConfirm: false})}
                >
{/*                    <Checkbox
                        defaultChecked={cancelChecked}
                        checked={cancelChecked}
                        onChange={e=>this.setState({cancelChecked: e.target.checked})}
                    >同时取消订单</Checkbox>*/}
                    确认退款吗？
                </Modal>
            </div>
        )
    }
}

// <Descriptions column={16} layout="vertical" size="small" style={{marginTop: 10}} bordered>
//     <Descriptions.Item label="预订项目" span={7}>AFTERNOON TEA | 下午茶</Descriptions.Item>
//     <Descriptions.Item label="单价" span={3}>￥500</Descriptions.Item>
//     <Descriptions.Item label="数量" span={3}>4</Descriptions.Item>
//     <Descriptions.Item label="小计" span={3}>￥2000</Descriptions.Item>
//     <Descriptions.Item label="活动抵扣" span={7}>AFTERNOON TEA | 下午茶</Descriptions.Item>
//     <Descriptions.Item label="优惠" span={3}>-￥500</Descriptions.Item>
//     <Descriptions.Item label="数量" span={3}>3</Descriptions.Item>
//     <Descriptions.Item label="小计" span={3}>-￥1500</Descriptions.Item>
// </Descriptions>