import React from 'react'
import { Menu, Dropdown, Table, Button, message, Modal, Badge, Select, DatePicker } from 'antd';
import TopBreadcrumb from 'components/TopBreadCrumb'
import moment from 'moment';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { get, post, patch, postDownloadFile } from 'common/utils.js';
import CouponEditor from './CouponEditor/CouponEditor.js';
import style from './index.module.scss';
import { COUPON_STATUS_ENUM } from './constrants.js';

const Column = Table.Column;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const breadcrumbList = [{ name: '券码管理' }, { name: '券码列表' }];

export default class CouponList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            total: undefined,
            dataList: [],
            serviceList: [],
            loading: false,
            editorVisible: false,
            status: 'ALL',
            editorId: null,
        }
    }

    componentDidMount() {
        this.getDataList(1);
        this.getServiceList();
    }

    getDataList = (page) => {
        let self = this;
        let { status } = this.state;
        self.setState({ loading: true });
        let params = {
            page_size: this.state.pageSize,
            page: page,
            status: (status === 'ALL' ? null : status),
        };
        // if(orderDay){
        //     params.order_day = moment(orderDay).format('YYYY-MM-DD');
        // }
        // if(createDay){
        //     params.create_day = moment(createDay).format('YYYY-MM-DD');
        // }
        // process.env.REACT_APP_SERVER_PATH
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/coupon/get_list', params).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results, total: retData.data.total, page: page})
            };
        });
    }

    getServiceList = () => {
        let self = this;
        let { status } = this.state;
        self.setState({ loading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/service_info/get_list', {
            page: 1,
            page_size: 999,
            status_list: "0,1"
        }).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ serviceList: retData.data.results})
            };
        });
    }

    editItem = r => {
        this.setState({ editorId: r.id, editorVisible: true });
    }

    createItem = _ => {
        // let editorData = {
        //     sys_id: 1,
        //     src_type: 1,
        //     weight: 1,
        //     tag_name: null,
        //     intervals: [{ "color": "#FB6060", "score": -100, "lower_limit": null, "upper_limit": null }, { "color": "#FFDA77", "score": 0, "lower_limit": null, "upper_limit": null }, { "color": "#5BAE8D", "score": 100, "lower_limit": null, "upper_limit": null }]
        // };
        // this.setState({ editorVisible: true, editorType: 'CREATE', editorData: editorData });
    }

    downloadCouponItems = r => {
        postDownloadFile(process.env.REACT_APP_SERVER_PATH + 'api/pc/coupon/download_coupon_items', {
            base_id: r.id
        });
    }

    render() {
        let self = this;
        let { editorVisible, editorId, service_id, serviceList, status } = this.state;
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                <div className="CouponList">
                    <div className="toolBar">
                        <div className="leftTools">
                            <Select
                                style={{ width: 110 }}
                                value={status}
                                onChange={v=>this.setState({status: v})}
                            >
                                <Option value='ALL' key='ALL'>全部状态</Option>
                                <Option value='0' key='0'>已停用</Option>
                                <Option value='1' key='1'>已启用</Option>
                            </Select>
                            {/*<div>下单时间</div>*/}
{/*                            <RangePicker
                                // showTime={{ format: 'HH:mm:ss' }}
                                format="YYYY-MM-DD"
                                placeholder={['开始日期', '结束日期']}
                                value={timeRange}
                                onChange={v=>{
                                    self.setState({timeRange: v});
                                }}
                            />*/}
{/*                            <DatePicker
                                // showTime={{ format: 'HH:mm:ss' }}
                                // format="YYYY-MM-DD"
                                style={{marginLeft: 10}}
                                placeholder="选择预订日期"
                                value={orderDay}
                                onChange={v=>{
                                    self.setState({orderDay: v});
                                }}
                            />
                            <DatePicker
                                // showTime={{ format: 'HH:mm:ss' }}
                                // format="YYYY-MM-DD"
                                style={{marginLeft: 10}}
                                placeholder="选择下单日期"
                                value={createDay}
                                onChange={v=>{
                                    self.setState({createDay: v});
                                }}
                            />*/}
                            <Button onClick={_ => this.getDataList(1)} type="primary" style={{marginLeft: 14}}>查询</Button>
                        </div>
                        <div className="rightTools">
                            <Button onClick={_ => this.setState({editorVisible: true, editorId: null})} type="primary"><PlusOutlined />添加</Button>
                        </div>
                    </div>
                    <div className="table_wrapper">
                        <Table
                            rowKey='id'
                            dataSource={this.state.dataList}
                            loading={this.state.loading}
                            pagination={{
                                defaultCurrent: 1,
                                total: this.state.total,
                                pageSize: this.state.pageSize,
                                showSizeChanger: true,
                                showQuickJumper: false,
                                current: this.state.page,
                            }}
                            onChange={(pagination, filters, sorter, extra) =>
                                this.setState({ pageSize: pagination.pageSize }, () => self.getDataList(pagination.current))
                            }
                            rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                        >
                            <Column
                                title='名称'
                                key='name'
                                align='center'
                                dataIndex="name"
                            />
                            <Column
                                title='预订项目'
                                key='service_name'
                                align='center'
                                dataIndex='service_name'
                            />
                            <Column
                                title='状态'
                                key='status'
                                align='center'
                                dataIndex='status'
                                render={status => {
                                    if (status in Object.keys(COUPON_STATUS_ENUM)) {
                                        return (<span><Badge color={COUPON_STATUS_ENUM[status + ''] ? COUPON_STATUS_ENUM[status + ''].color : null} />{COUPON_STATUS_ENUM[status + ''].text}</span>);
                                    } else {
                                        return '';
                                    }
                                }}
                            />
                            <Column
                                title='券码类型'
                                key='type'
                                align='center'
                                dataIndex='type'
                                render={text => {
                                    if(text == 'RANDOM_CODE'){
                                        return '随机码';
                                    }else if(text == 'SAME_CODE'){
                                        return '通用码';
                                    }else{
                                        return '';
                                    }
                                }}
                            />
                            <Column
                                title='已使用/总数量'
                                key='num'
                                align='center'
                                render={r => {
                                    return r.used_num + '/' + r.num;
                                }}
                            />
                            <Column
                                title='起止时间'
                                key='day_begin_end'
                                align='center'
                                render={r => {
                                    let day_begin_str = '(未填写)';
                                    let day_end_str = '(未填写)';
                                    if(r.day_begin){
                                        day_begin_str = moment(r.day_begin).format('YYYY年M月D日');
                                    }
                                    if(r.day_end){
                                        day_end_str = moment(r.day_end).format('YYYY年M月D日');
                                    }
                                    return day_begin_str + ' ~ ' + day_end_str;
                                }}
                            />
                            <Column
                                title='创建时间'
                                key='create_time'
                                align='center'
                                render={r => {
                                    return moment(r.create_time).format('YYYY年M月D日 HH:mm:ss')
                                }}
                            />
                            <Column
                                title='操作'
                                key='opts'
                                align='center'
                                render={(text, record) => [
                                    <span key="1">
                                        <Button type="link" size="small" onClick={(e) => this.downloadCouponItems(record)} style={{ color: '#357CF7' }}>导出</Button>
                                        <Button type="link" size="small" onClick={(e) => this.editItem(record)} style={{ color: '#357CF7' }}>编辑</Button>
                                    </span>
                                    // ,
                                    // <Dropdown key="dropdown" overlay={(
                                    //     <Menu onClick={({ key }) => {
                                    //         if (key == 'PAUSE') {
                                    //             self.updateStatus(record, 0);
                                    //         } else if (key == 'RESUME') {
                                    //             self.updateStatus(record, 1);
                                    //         } else if (key == 'DEL') {
                                    //             self.delRule(record);
                                    //         }
                                    //     }} key="2">
                                    //         {record.status !== 0 && <Menu.Item key="PAUSE">停用</Menu.Item>}
                                    //         {record.status !== 1 && <Menu.Item key="RESUME">启用</Menu.Item>}
                                    //         <Menu.Item key="DEL">删除</Menu.Item>
                                    //     </Menu>
                                    // )}>
                                    //     <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: '#357CF7' }}>
                                    //         更多 <DownOutlined />
                                    //     </a>
                                    // </Dropdown>
                                ]}
                            />
                        </Table>
                    </div>
                </div>
                {
                    editorVisible &&
                    <CouponEditor
                        visible={editorVisible}
                        serviceList={serviceList}
                        id={editorId}
                        onSuccess={this.getDataList}
                        changeVisible={v => this.setState({ editorVisible: v}, ()=>this.getDataList(this.state.page))}
                    />
                }
            </div>
        )
    }
}
