import React from 'react'
import { Menu, Dropdown, Table, Button, message, Modal, Badge, Select, DatePicker, Input } from 'antd';
import TopBreadcrumb from 'components/TopBreadCrumb'
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { get, post, patch, postDownloadFile } from 'common/utils.js';
import style from './index.module.scss';

const Column = Table.Column;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const breadcrumbList = [{ name: '客户信息' }, { name: '客户列表' }]

export default class CustomerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            total: undefined,
            dataList: [],
            loading: false,
            keyword: null
        }
    }

    componentDidMount() {
        this.getDataList(1);
    }

    getDataList = (page) => {
        let self = this;
        let { keyword } = this.state;
        self.setState({ loading: true });
        let params = {
            page_size: this.state.pageSize,
            page: page,
            keyword: keyword
        };

        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/customer/get_list', params).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results, total: retData.data.total, page: page})
            };
        });
    }

    downloadCustomerList = r => {
        postDownloadFile(process.env.REACT_APP_SERVER_PATH + 'api/pc/customer/download_customer_list', {
        });
    }

    render() {
        let self = this;
        let { dataList, total, keyword } = this.state;
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                <div className="CustomerList">
                    <div className="toolBar">
                        <div className="leftTools">
                            <Input
                                placeholder="请输入查询信息"
                                style={{width: "180px"}}
                                value={keyword}
                                onChange={e=>{
                                    self.setState({keyword: e.target.value});
                                }}
                            />
{/*                            <Select
                                style={{ width: 130 }}
                                value={orderStatus}
                                onChange={v=>this.setState({orderStatus: v})}
                            >
                                <Option value='ALL' key='ALL'>全部状态订单</Option>
                                <Option value='0' key='0'>已取消订单</Option>
                                <Option value='1' key='1'>待支付订单</Option>
                                <Option value='2' key='2'>已支付订单</Option>
                                <Option value='3' key='3'>已核销订单</Option>
                            </Select>*/}
                            {/*<div>下单时间</div>*/}
{/*                            <RangePicker
                                // showTime={{ format: 'HH:mm:ss' }}
                                format="YYYY-MM-DD"
                                placeholder={['开始日期', '结束日期']}
                                value={timeRange}
                                onChange={v=>{
                                    self.setState({timeRange: v});
                                }}
                            />
                            <DatePicker
                                // showTime={{ format: 'HH:mm:ss' }}
                                // format="YYYY-MM-DD"
                                style={{marginLeft: 10}}
                                placeholder="选择预订日期"
                                value={orderDay}
                                onChange={v=>{
                                    self.setState({orderDay: v});
                                }}
                            />
                            <DatePicker
                                // showTime={{ format: 'HH:mm:ss' }}
                                // format="YYYY-MM-DD"
                                style={{marginLeft: 10}}
                                placeholder="选择下单日期"
                                value={createDay}
                                onChange={v=>{
                                    self.setState({createDay: v});
                                }}
                            />*/}
                            <Button onClick={_ => this.getDataList(1)} type="primary" style={{marginLeft: 14}}>查询</Button>
                        </div>
                        <div className="rightTools">
                            <Button onClick={_ => this.downloadCustomerList()}><DownloadOutlined />导出</Button>
                        </div>
                    </div>
                    <div className="table_wrapper">
                        <Table
                            rowKey='id'
                            dataSource={this.state.dataList}
                            loading={this.state.loading}
                            pagination={{
                                defaultCurrent: 1,
                                total: this.state.total,
                                pageSize: this.state.pageSize,
                                showSizeChanger: true,
                                showQuickJumper: false,
                                current: this.state.page,
                            }}
                            onChange={(pagination, filters, sorter, extra) =>
                                this.setState({ pageSize: pagination.pageSize }, () => self.getDataList(pagination.current))
                            }
                            rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                        >
                            <Column
                                title='姓名'
                                key='name'
                                width={180}
                                align='center'
                                dataIndex='name'
                            />
                            <Column
                                title='性别'
                                key='gender'
                                align='center'
                                render={r => {
                                    if(r.gender === 1){
                                        return '先生';
                                    }else if(r.gender === 2){
                                        return '女士';
                                    }else{
                                        return '';
                                    }
                                }}
                            />
                            <Column
                                title='餐饮订单数'
                                key='c_order_num'
                                align='center'
                                dataIndex='c_order_num'
                            />
                            <Column
                                title='手机号'
                                key='mobile'
                                render={r => {
                                    let text = '';
                                    if(r.mobile){
                                        text = r.mobile;
                                    }
                                    if(r.mobile_region){
                                        text = '('+r.mobile_region+')'+text;
                                    }
                                    return text;
                                }}
                            />
                            <Column
                                title='邮箱'
                                key='email'
                                dataIndex='email'
                            />
                            <Column
                                title='证件类型'
                                key='identity_type'
                                dataIndex='identity_type'
                                render={t => {
                                    if(t == 1){
                                        return '身份证'
                                    }else if(t == 2){
                                        return '护照';
                                    }else if(t == 3){
                                        return '其他证件';
                                    }else{
                                        return '';
                                    }
                                }}
                            />
                            <Column
                                title='证件号'
                                key='identity_number'
                                dataIndex='identity_number'
                            />
                            <Column
                                title='操作'
                                key='opts'
                                align='center'
                                render={(r) => [
                                    <span key="1">
                                        <Button type="link" size="small" onClick={(e) => this.props.history.push('/System/Customer?id='+r.id)} style={{ color: '#357CF7' }}>详情</Button>
                                    </span>
                                    // ,
                                    // <Dropdown key="dropdown" overlay={(
                                    //     <Menu onClick={({ key }) => {
                                    //         if (key == 'PAUSE') {
                                    //             self.updateStatus(record, 0);
                                    //         } else if (key == 'RESUME') {
                                    //             self.updateStatus(record, 1);
                                    //         } else if (key == 'DEL') {
                                    //             self.delRule(record);
                                    //         }
                                    //     }} key="2">
                                    //         {record.status !== 0 && <Menu.Item key="PAUSE">停用</Menu.Item>}
                                    //         {record.status !== 1 && <Menu.Item key="RESUME">启用</Menu.Item>}
                                    //         <Menu.Item key="DEL">删除</Menu.Item>
                                    //     </Menu>
                                    // )}>
                                    //     <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: '#357CF7' }}>
                                    //         更多 <DownOutlined />
                                    //     </a>
                                    // </Dropdown>
                                ]}
                            />
                        </Table>
                    </div>
                </div>
{/*                {
                    editorVisible &&
                    <OrderEditor
                        visible={editorVisible}
                        id={editorData.id}
                        onSuccess={this.getDataList}
                        changeVisible={v => this.setState({ editorVisible: v}, ()=>this.getDataList(this.state.page))}
                    />
                }*/}
            </div>
        )
    }
}
