import React from 'react'
import { Tabs, Spin, message, Modal, Select } from 'antd';
import { ProfileOutlined, PayCircleOutlined, UserOutlined } from '@ant-design/icons';
import { get, post, patch } from 'common/utils.js';
import style from './OrderEditor.module.scss';
import OrderInfoPane from './OrderInfoPane.js';
import OrderPayPane from './OrderPayPane.js';
import GuestsPane from './GuestsPane.js';

const Option = Select.Option;
const TabPane = Tabs.TabPane;

export default class OrderEditor extends React.Component {
    constructor(props) {
        super(props);
        let d = props.data;
        this.state = {
            loading: true,
            c_order_info: null,
            pay_order_info: null,
        }
    }

    componentWillMount() {
        this.getData(this.props.id)
    }

    getData = id => {
        let self = this;
        self.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/get_one', {
            id: id
        }).then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.errcode === 0) {
                self.setState({
                    c_order_info: retData.data.catering_order,
                    pay_order_info: retData.data.pay_order,
                    user_list: retData.data.user_list
                })
            };
        });
    }

    render() {
        let self = this;
        const { visible } = this.props;
        const { loading, c_order_info, pay_order_info, user_list } = this.state;

        return (
            <Modal
                visible={visible}
                className={style["wrapper"]}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
                style={{}}
                width={1000}
                // title="订单详情"
                title={null}
                footer={null}
                onCancel={_=>this.props.changeVisible(false)}
            >
                {
                    loading === true &&
                    <Spin loading={true}></Spin>
                }
                {
                    pay_order_info && c_order_info &&
                    <Tabs defaultActiveKey="1">
                        <TabPane
                            tab={<span><ProfileOutlined />预订信息</span>}
                            key="1"
                        >
                            <OrderInfoPane
                                c_order_info={c_order_info}
                                pay_order_info={pay_order_info}
                                onRefresh={()=>self.getData(self.props.id)}
                                onClose={()=>this.props.changeVisible(false)}
                            />
                        </TabPane>
                        <TabPane
                            tab={<span><PayCircleOutlined />支付信息</span>}
                            key="2"
                        >
                            <OrderPayPane
                                c_order_info={c_order_info}
                                pay_order_info={pay_order_info}
                                onRefresh={()=>self.getData(self.props.id)}
                                onClose={()=>this.props.changeVisible(false)}
                            />
                        </TabPane>
                        <TabPane
                            tab={<span><UserOutlined />实名信息</span>}
                            key="people"
                        >
                            <GuestsPane
                                id={this.props.id}
                                rsvd_num={c_order_info?.rsvd_num}
                                user_list={user_list}
                                onRefresh={()=>self.getData(self.props.id)}
                                onClose={()=>this.props.changeVisible(false)}
                            />
                        </TabPane>
                    </Tabs>
                }
            </Modal>
        )
    }
}
