import React from 'react'
import { Tabs } from 'antd';
import TopBreadcrumb from 'components/TopBreadCrumb'
import BasicInfoPane from './BasicInfoPane';
import OrderListPane from './OrderListPane';
import { ProfileOutlined, PayCircleOutlined } from '@ant-design/icons';
import { getQueryString } from 'common/utils.js';
import style from './index.module.scss';

const TabPane = Tabs.TabPane;

const breadcrumbList = [{ name: '客户信息', path: '/System/CustomerList'}, { name: '客户详情' }];

export default class CustomerDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: getQueryString('id')
        }
    }

    componentDidMount() {
    }

    render() {
        let self = this;
        let { customerId } = this.state;
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
            >
                <TopBreadcrumb breadcrumbList={breadcrumbList} />
                <div className="CustomerDetails">
                    <Tabs defaultActiveKey="1">
                        <TabPane
                            tab={<span><ProfileOutlined />基本信息</span>}
                            key="1"
                        >
                            <BasicInfoPane
                                customerId={customerId}
                            />
                        </TabPane>
                        <TabPane
                            tab={<span><PayCircleOutlined />客户订单</span>}
                            key="2"
                        >
                            <OrderListPane
                                customerId={customerId}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
