import React from 'react';
import style from './index.module.scss';
import { Modal } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

export default class Comp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
    }

    delItem = ()=>{
        let self = this;
        Modal.confirm({
            content: `确认删除吗?`,
            onOk: () => {
                self.props.delItem && self.props.delItem();
            },
        });
    }

    render() {
        // const { loading } = this.state;
        const { imageUrl } = this.props;

        return (
            <div className={style['wrapper']}>
                <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                {
                    <div className="del_mask">
                        {
                            this.props.allowDelete &&
                            <DeleteOutlined style={{marginRight: "20px"}} onClick={()=>this.delItem()} />
                        }
                        <EyeOutlined onClick={()=>this.props.displayPhotoSwipe()} />
                    </div>
                }
            </div>
        )
    }
}
