import React, { Component } from 'react';
import Config from '../../common/config.js';
import { get, post, getQueryString } from 'common/utils.js';
import moment from 'moment';
import { Descriptions, Button, message, Table, Modal } from 'antd';
import style from './CaterOrderDetails.module.scss';

const Column = Table.Column;

const STATUS_TEXT = {
    '-1': '已删除',
    '0': '已取消',
    '1': '已下单（待支付）',
    '2': '已支付订金',
    '3': '已核销',
}

export default class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rezCode: getQueryString('rez_code'),
            id: getQueryString('id'),
            name: '',
            phone: '',
            gender: null,
            c_order_info: {},
            pay_order_info: {},
            user_list: [],
        };

        let self = this;
        // if(window.wxIsReady == true){
        //     // alert('window.wxIsReady == true');
        //     this.doWXScan();
        // }else{
        //     document.body.addEventListener('wxIsReady', function (e) {
        //         // alert('wxIsReady event triggered');
        //         self.doWXScan();
        //     }, false);
        // }

        // wx.ready(()=>{
        //     self.setState({wechatReady: true});
        //     if(self.state.isFirstEntered == true){
                
        //     }
        // });
        // wx.error(res=>{
        //     alert('扫码初始化失败：'+JSON.stringify(res));
        //     self.setState({wechatReady: false});
        // });
    }

    componentDidMount() {
        this.getData(this.state.rezCode, this.state.id);
    }

    getData = (rezCode, id) => {
        let self = this;
        self.setState({loading: true});
        let params = {};
        if(rezCode !== null){
            params = {rez_code: rezCode};
        }else if(id !== null){
            params = {id: id}
        }
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/get_one', params).then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.errcode === 0) {
                self.setState({
                    c_order_info: retData.data.catering_order,
                    pay_order_info: retData.data.pay_order,
                    user_list: retData.data.user_list
                })
            };
        });
    }

    writeoffQrcode = (rezCode, id) => {
        let self = this;
        let params = {};
        if(rezCode !== null){
            params = {rez_code: rezCode};
        }else if(id !== null){
            params = {id: id}
        }
        Modal.confirm({
            content: `确认核销吗?`,
            onOk: () => {
                self.setState({writeOffloading: true});
                post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/write_off_order', params).then(retData => {
                    self.setState({writeOffloading: false});
                    if (retData.data && retData.data.errcode === 0) {
                        message.success('核销成功');
                        self.getData(rezCode, id);
                    }else if(retData.data && retData.data.errcode !== 0 && retData.data.msg){
                        message.error(retData.data.msg);
                    }else{
                        message.error('核销失败');
                    }
                });
            },
        });
    }

    render() {
        let self = this;
        const { c_order_info, pay_order_info, user_list, writeOffloading } = this.state;
        let gender_text = '';
        if(c_order_info.user_gender == 1){
            gender_text = '先生';
        }else if(c_order_info.user_gender == 2){
            gender_text = '女士';
        }

        let orig_sum = 0;
        let extra_data = {};
        if(c_order_info['extra_data'] != null){
            extra_data = c_order_info['extra_data'];
        }
        if(c_order_info.rsvd_num !== null &&
            c_order_info.rsvd_num !== undefined &&
            extra_data.unit_price !== null &&
            extra_data.unit_price !== undefined){
            orig_sum = extra_data.unit_price * c_order_info.rsvd_num;
        }

        return (
            <div className={style["wrapper"]}>
                <Descriptions column={1} bordered size='small' style={{width: '100%'}}>
                    <Descriptions.Item label="预订项目">{extra_data.s_name}</Descriptions.Item>
                    <Descriptions.Item label="预订号">{c_order_info.sn}</Descriptions.Item>
                    <Descriptions.Item label="订单状态">{STATUS_TEXT[c_order_info.status+'']}</Descriptions.Item>
                    <Descriptions.Item label="预订席位">{c_order_info.rsvd_num}</Descriptions.Item>
                    <Descriptions.Item label="预订日期">
                        <div
                            className="info_item"
                            onClick={()=>{
                                if(self.state.mode !== 'DISPLAY'){
                                    message.error("请先保存");
                                }else{
                                    self.setState({mode: 'EDIT_TIME', datePickerValue: moment(c_order_info.day)});
                                }
                            }}
                        >
                            {c_order_info.day && moment(c_order_info.day).format('YYYY年M月D日')}
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="备注">
                        <div
                            className="info_item"
                            onClick={()=>{
                                if(self.state.mode !== 'DISPLAY'){
                                    message.error("请先保存");
                                }else{
                                    self.setState({mode: 'EDIT_REMARK', remark: c_order_info.remark});
                                }
                            }}
                            style={{width: 180}}
                        >
                            {c_order_info.remark}
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="预订联系人">{c_order_info.user_name} {gender_text}</Descriptions.Item>
                    <Descriptions.Item label="联系方式">{c_order_info.user_mobile}</Descriptions.Item>

                    <Descriptions.Item label="下单时间">{c_order_info.create_time}</Descriptions.Item>
                    <Descriptions.Item label="支付流水号">{pay_order_info.ext_sn}</Descriptions.Item>
                    <Descriptions.Item label="付款时间">{pay_order_info.pay_time}</Descriptions.Item>
                </Descriptions>
                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    style={{marginTop: 4}}
                    dataSource={[
                        {
                            'id': 1,
                            'name': extra_data.s_name,
                            'unit_price': extra_data.unit_price,
                            'rsvd_num': c_order_info.rsvd_num,
                            'sum_price': pay_order_info.amount
                        }
                    ]}
                >
                    <Column
                        title='预订项目'
                        key='name'
                        width={245}
                        dataIndex='name'
                    />
                    <Column
                        title='单价'
                        key='unit_price'
                        align='center'
                        width={80}
                        dataIndex='unit_price'
                        render={(text)=><span>￥{text}</span>}
                    />
                    <Column
                        title='数量'
                        key='rsvd_num'
                        align='center'
                        width={80}
                        dataIndex='rsvd_num'
                    />
                    <Column
                        title='小计'
                        key='sum_price'
                        align='center'
                        width={80}
                        dataIndex='sum_price'
                        render={(text)=><span>￥{text}</span>}
                    />
                </Table>
                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    locale={{emptyText:"无活动抵扣"}}
                    dataSource={[
                        // {
                        //     'id': 1,
                        //     'name': 'AFTERNOON TEA | 下午茶',
                        //     'price': -500,
                        //     'num': 3,
                        //     'sum_price': -1500
                        // },{
                        //     'id': 1,
                        //     'name': 'AFTERNOON TEA | 下午茶2',
                        //     'price': -100,
                        //     'num': 1,
                        //     'sum_price': -100
                        // }
                    ]}
                >
                    <Column
                        title='活动抵扣'
                        key='name'
                        width={245}
                        dataIndex='name'
                    />
                    <Column
                        title='优惠'
                        key='price'
                        align='center'
                        width={80}
                        dataIndex='price'
                        render={(text)=><span>￥{text}</span>}
                    />
                    <Column
                        title='数量'
                        key='num'
                        align='center'
                        width={80}
                        dataIndex='num'
                    />
                    <Column
                        title='小计'
                        key='sum_price'
                        align='center'
                        width={80}
                        dataIndex='sum_price'
                        render={(text)=><span>￥{text}</span>}
                    />
                </Table>
                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    showHeader={false}
                    dataSource={[
                        {
                            'id': 1,
                            'name': '应付：',
                            'amount': orig_sum
                        },{
                            'id': 2,
                            'name': '实付：',
                            'amount': pay_order_info.amount
                        }
                    ]}
                >
                    <Column
                        key='name'
                        width={405}
                        dataIndex='name'
                        render={(text)=><span style={{fontWeight: 'bold'}}>{text}</span>}
                    />
                    <Column
                        key='amount'
                        width={80}
                        align='center'
                        dataIndex='amount'
                        render={(text)=><span style={{fontWeight: 'bold'}}>￥{text}</span>}
                    />
                </Table>

                <Table
                    rowKey='id'
                    size="small"
                    pagination={false}
                    bordered={true}
                    style={{marginTop: 4}}
                    dataSource={user_list.map(item=>{
                        return {
                            'id': item.id,
                            'name': item.user_name,
                            'gender': item.gender,
                            'identity_number': item.identity_number,
                        }
                    })}
                >
                    <Column
                        title='客人'
                        key='name'
                        width={50}
                        align='center'
                        render={(record, x, index)=>{
                            return index + 1;
                        }}
                    />
                    <Column
                        title='姓名'
                        key='name'
                        align='center'
                        width={120}
                        dataIndex='name'
                        // render={(text)=><span>￥{text}</span>}
                    />
                    <Column
                        title='称谓'
                        key='gender'
                        align='center'
                        width={100}
                        dataIndex='gender'
                        render={(text)=>{
                            if(text == 1){
                                return '先生';
                            }else if(text == 2){
                                return '女士';
                            }else{
                                return '';
                            }
                        }}
                    />
                    <Column
                        title='证件号'
                        key='identity_number'
                        align='center'
                        width={180}
                        dataIndex='identity_number'
                        render={(text)=>{
                            return text;
                        }}
                    />
                </Table>
                {
                    this.state.c_order_info.status === 2 &&
                    <div className="btn-line" style={{width: '190px'}}>
                        <Button onClick={_=>window.history.back()}>返回</Button>
                        <Button type="primary" onClick={_=>this.writeoffQrcode(this.state.rezCode, this.state.id)} loading={writeOffloading}>核销</Button>
                    </div>
                }
                <div className="btn-line">
                {
                    this.state.c_order_info.status !== 2 &&
                    <Button onClick={_=>window.history.back()}>返回</Button>
                }
                </div>
            </div>
        )
    }
}
