import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Modal } from 'antd'
import TopBreadCrumb from 'components/TopBreadCrumb'
import { post, get, getCachedUserInfo } from 'common/utils'
import md5 from 'md5'
import style from './index.module.scss'
import { withRouter } from 'react-router'

const Com = props => {
  const { history } = props
  const breadcrumbList = [{ name: '设置' }, { name: '个人中心' }]
  const [personalForm] = Form.useForm()
  const [userRoles, setUserRoles] = useState([])
  const [personalInfo] = useState(() => {
    return getCachedUserInfo();
  })

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }

  useEffect(() => {
    if (!personalInfo) {
      message.error('用户尚未登陆！')
      return
    }
    const { user_id } = personalInfo
    getUserRolesById(user_id)
  }, [personalInfo])

  /** 根据用户id获取用户信息 */
  const getUserRolesById = userId => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/user/users', {
      page: 1,
      page_size: 1,
      ids: userId + ''
    }).then(resultData => {
      if (!!resultData.data && !!resultData.data.results && resultData.data.results.length > 0) {
        const userInfo = resultData.data.results[0]
        const { roles } = userInfo
        setUserRoles(roles || [])
      }
    })
  }

  /** 修改密码 */
  const changePassword = async () => {
    try {
      const values = await personalForm.validateFields()
      const { original_password, new_password } = values
      post(process.env.REACT_APP_SERVER_PATH + 'api/auth/user/change_self_password', {
        orig_pwd: md5(original_password),
        new_pwd: md5(new_password)
      }).then(retData => {
        if (!!retData.data) {
          if (retData.data.errcode === 0) {
            Modal.success({
              content: '密码修改成功，请重新登陆',
              onOk: () => logout()
            })
          } else {
            const { msg } = retData.data
            message.error(msg)
          }
        } else {
          message.error('密码修改失败,请重试！')
        }
      })
    } catch (errorInfo) { }
  }

  /** 退出登录 */
  const logout = () => {
    localStorage.clear()
    sessionStorage.clear()
    history.push('/Login')
  }

  return (
    <div className={style['personalCenter']}>
      <div className="content-wrapper">
        <TopBreadCrumb breadcrumbList={breadcrumbList} />
        <div className="content-header">个人中心</div>
        <div className="content-main">
          <div className="left"></div>
          <div className="right">
            {personalInfo && (
              <>
                <div>
                  <label>用户名</label>
                  <span>{personalInfo.name}</span>
                </div>
                <div style={{ borderBottom: '1px solid rgba(201, 201, 201, .5)' }}>
                  <label>角色</label>
                  <span>{userRoles.map(roleItem => roleItem.name).join(',')}</span>
                </div>
                <div
                  style={{
                    alignItems: 'flex-start',
                    marginTop: 40,
                    borderBottom: '1px solid rgba(201, 201, 201, .5)'
                  }}
                >
                  <label>修改密码</label>
                  <Form {...layout} form={personalForm} autoComplete="off">
                    <Form.Item
                      name="original_password"
                      rules={[{ required: true, message: '* 请输入原密码' }]}
                    >
                      <Input.Password placeholder="输入原密码" maxLength={12} />
                    </Form.Item>
                    <Form.Item
                      name="new_password"
                      rules={[
                        { required: true, message: '* 请输入新密码' },
                        {
                          pattern: /^[^\u4E00-\u9FA5\uF900-\uFA2D\u0020]{6,12}$/g,
                          message: '* 密码应为6-12个字符'
                        }
                      ]}
                    >
                      <div>
                        <Input.Password placeholder="输入新密码" maxLength={12} />
                        <div className="input-tip">6-12个字符</div>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="check_new_password"
                      dependencies={['new_password']}
                      rules={[
                        { required: true, message: '* 请再次输入新密码' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('new_password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('* 两次密码输入不一致'))
                          }
                        })
                      ]}
                    >
                      <Input.Password placeholder="再次输入新密码" maxLength={12} />
                    </Form.Item>
                  </Form>
                </div>
              </>
            )}
            <div className="footer">
              {/* <Button type="primary" ghost>
                取消
              </Button> */}
              <Button type="primary" onClick={changePassword}>
                确定
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Com)
