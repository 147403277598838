import React from 'react';
import style from './index.module.scss';

export default class Comp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        const { color, price, total_num, rsvd_num, name } = this.props;

        return (
            <div className={style['wrapper']} style={{ backgroundColor: color }}>
                <div className="top">{name}</div>
                <div className="bottom">
                    <div className="price">单价：¥{price}</div>
                    <div className="total">配置数量：{total_num}</div>
                    <div className="rsvd_title">已预订：</div>
                    <div className="rsvd_value">{rsvd_num}</div>
                    <div className="remain_title">剩余：</div>
                    <div className="remain_value">{total_num - rsvd_num}</div>
                </div>
            </div>
        )
    }
}
