import React from 'react'
import { Menu, Dropdown, Table, Button, message, Modal, Badge, Select, DatePicker } from 'antd';
import TopBreadcrumb from 'components/TopBreadCrumb'
import moment from 'moment';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import { get, post, patch, reqDelete } from 'common/utils.js';
import OrderEditor from '../../CateringOrderList/OrderEditor/OrderEditor.js';
import style from './index.module.scss';
import { ORDER_STATUS_ENUM } from '../../CateringOrderList/constrants.js';

const Column = Table.Column;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const breadcrumbList = [{ name: '餐饮预订' }, { name: '预订记录' }]

export default class CateringOrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sys_id: "ALL",
            page: 1,
            pageSize: 10,
            total: undefined,
            dataList: [],
            serviceList: [],
            loading: false,
            editorVisible: false,
            editorData: {},
            orderStatus: "ALL",
            orderDay: null,
            createDay: null,
        }
    }

    componentDidMount() {
        this.getDataList(1);
    }

    getDataList = (page) => {
        let self = this;
        let { orderDay, createDay, orderStatus } = this.state;
        let { customerId } = this.props;
        self.setState({ loading: true });
        let params = {
            page_size: this.state.pageSize,
            page: page,
            user_id: customerId
        };
        post(process.env.REACT_APP_SERVER_PATH + 'api/pc/catering/order/get_list', params).then(retData => {
            self.setState({ loading: false });
            if (!!retData.data && retData.data.results) {
                self.setState({ dataList: retData.data.results, total: retData.data.total, page: page})
            };
        });
    }

    editItem = r => {
        this.setState({ editorData: r, editorVisible: true, editorType: 'EDIT' });
    }

    render() {
        let self = this;
        let { editorVisible, editorData, service_id, serviceList, orderStatus, orderDay, createDay } = this.state;
        return (
            <div
                className={style["wrapper"]}
                style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div className="OrderList">
                    <div className="table_wrapper">
                        <Table
                            rowKey='id'
                            dataSource={this.state.dataList}
                            loading={this.state.loading}
                            pagination={{
                                defaultCurrent: 1,
                                total: this.state.total,
                                pageSize: this.state.pageSize,
                                showSizeChanger: true,
                                showQuickJumper: false,
                                current: this.state.page,
                            }}
                            onChange={(pagination, filters, sorter, extra) =>
                                this.setState({ pageSize: pagination.pageSize }, () => self.getDataList(pagination.current))
                            }
                            rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                        >
                            <Column
                                title='订单号'
                                key='sn'
                                width={180}
                                align='center'
                                dataIndex='sn'
                            />
                            <Column
                                title='下单时间'
                                key='create_time'
                                align='center'
                                render={r => {
                                    return moment(r.create_time).format('YYYY年M月D日 HH:mm:ss')
                                }}
                            />
                            <Column
                                title='预订项目'
                                key='service_name'
                                dataIndex='service_name'
                            />
                            <Column
                                title='预订日期'
                                key='day'
                                align='center'
                                render={r => {
                                    return moment(r.day).format('YYYY年M月D日')
                                }}
                            />
                            <Column
                                title='预订席位'
                                key='rsvd_num'
                                align='center'
                                dataIndex='rsvd_num'
                            />
                            <Column
                                title='联系人'
                                key='user_name'
                                dataIndex='user_name'
                            />
                            <Column
                                title='联系方式'
                                key='user_mobile'
                                dataIndex='user_mobile'
                            />
                            <Column
                                title='订单状态'
                                key='status'
                                align='center'
                                dataIndex='status'
                                render={status => {
                                    if (status in Object.keys(ORDER_STATUS_ENUM)) {
                                        return (<span><Badge color={ORDER_STATUS_ENUM[status + ''] ? ORDER_STATUS_ENUM[status + ''].color : null} />{ORDER_STATUS_ENUM[status + ''].text}</span>);
                                    } else {
                                        return '';
                                    }
                                }}
                            />
                            <Column
                                title='操作'
                                key='opts'
                                align='center'
                                render={(text, record) => [
                                    <span onClick={() => this.setState({ currentUserId: record.id })} key="1">
                                        <Button type="link" size="small" onClick={(e) => this.editItem(record)} style={{ color: '#357CF7' }}>详情</Button>
                                    </span>
                                    // ,
                                    // <Dropdown key="dropdown" overlay={(
                                    //     <Menu onClick={({ key }) => {
                                    //         if (key == 'PAUSE') {
                                    //             self.updateStatus(record, 0);
                                    //         } else if (key == 'RESUME') {
                                    //             self.updateStatus(record, 1);
                                    //         } else if (key == 'DEL') {
                                    //             self.delRule(record);
                                    //         }
                                    //     }} key="2">
                                    //         {record.status !== 0 && <Menu.Item key="PAUSE">停用</Menu.Item>}
                                    //         {record.status !== 1 && <Menu.Item key="RESUME">启用</Menu.Item>}
                                    //         <Menu.Item key="DEL">删除</Menu.Item>
                                    //     </Menu>
                                    // )}>
                                    //     <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ color: '#357CF7' }}>
                                    //         更多 <DownOutlined />
                                    //     </a>
                                    // </Dropdown>
                                ]}
                            />
                        </Table>
                    </div>
                </div>
                {
                    editorVisible &&
                    <OrderEditor
                        visible={editorVisible}
                        id={editorData.id}
                        onSuccess={this.getDataList}
                        changeVisible={v => this.setState({ editorVisible: v}, ()=>this.getDataList(this.state.page))}
                    />
                }
            </div>
        )
    }
}
